import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useQueryData, useToast, useAuth } from "hooks";
import {
  GET_MISSION_ROLE_APPLICANTS,
  GET_MISSION_ROLE_INVITATIONS,
  GET_MISSION_ROLE_RECOMMENDATIONS,
} from "graphql/queries";
import {
  UNSELECT_APPLICANT,
  SELECT_APPLICANT,
  INTERVIEW_APPLICANT,
  SHORTLIST_APPLICANT,
  ADD_MISSION_ROLE_INVITATION,
  MOVE_APPLICANT_TO_APPLIED,
} from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";
import { ICON_TYPE } from "components/Icon";
import { APPLICANT_TYPES, MISSION_ROLE_APPLICANTS_PER_PAGE } from "constants/index";

/**
 * useApplicants
 *
 * @params {Number}   resultsPerPage
 * @params {String}   applicantState
 * @params {String}   missionId
 * @params {String}   missionRoleId
 * @params {String}   keyword
 * @params {String}   organizationId
 * @params {Boolean}  published
 * @params {Boolean}  skipQuery
 * @params {String}   paginationType
 * @params {Number}   currentPage
 * @params {Object}   filters
 * @params {Object}   variables
 */
export default function useApplicants({
  resultsPerPage = MISSION_ROLE_APPLICANTS_PER_PAGE,
  paginationType = PAGINATION_TYPE.classic,
  applicantState,
  missionId,
  missionRoleId,
  organizationId,
  isMember,
  keyword,
  published,
  filters,
  variables: variablesProp = {},
  skipQuery = false,
  sortKey,
  sortDirection,
} = {}) {
  const { toast } = useToast();
  const { isShowcase, isClient } = useAuth();
  const [stateFilter, setStateFilter] = useState(applicantState === null ? {} : { state: applicantState });
  const [queryName, setQueryName] = useState(GET_MISSION_ROLE_APPLICANTS);
  const [keyName, setKeyName] = useState(QUERY_KEYNAMES.applicants);
  const [unselectedApplicant] = useMutation(UNSELECT_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [shortlistApplicant] = useMutation(SHORTLIST_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [selectedApplicant] = useMutation(SELECT_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [interviewApplicant] = useMutation(INTERVIEW_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [inviteApplicant] = useMutation(ADD_MISSION_ROLE_INVITATION, {
    refetchQueries: ["missionRoleInvitations", "missionRoleRecommendations"],
  });
  const [moveApplicantToApplied] = useMutation(MOVE_APPLICANT_TO_APPLIED, {
    refetchQueries: ["missionRoleApplicants"],
  });

  useEffect(() => {
    switch (applicantState) {
      case null:
        return setQueryName(GET_MISSION_ROLE_INVITATIONS), setKeyName(QUERY_KEYNAMES.invitations);
      case APPLICANT_TYPES.recommended:
        return setQueryName(GET_MISSION_ROLE_RECOMMENDATIONS), setKeyName(QUERY_KEYNAMES.recommendations);
      default:
        return setQueryName(GET_MISSION_ROLE_APPLICANTS), setKeyName(QUERY_KEYNAMES.applicants);
    }
  }, [applicantState, setQueryName, setKeyName]);

  const variables = {
    filters:
      filters ||
      (applicantState === APPLICANT_TYPES.recommended
        ? {
            missionRoleId,
            missionId,
            organizationId,
            published,
          }
        : {
            name: keyword,
            missionId,
            missionRoleId,
            organizationId,
            published,
            ...(applicantState && { isMember }),
            ...stateFilter,
          }),
    orderBy: {
      [sortKey]: sortDirection,
    },
    ...(applicantState === APPLICANT_TYPES.pending ? { includeApplicationChecklist: true } : {}),
    ...variablesProp,
  };

  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    error,
    data,
    refetch,
  } = useQueryData({
    queryName,
    keyName,
    resultsPerPage,
    paginationType,
    variables: {
      // For showcase or client we show estimates while for admins we show application rate
      includeSellingPriceEstimates: isShowcase || isClient,
      ...variables,
    },
    skip: skipQuery,
  });

  useEffect(() => {
    setStateFilter(applicantState === null ? {} : { state: applicantState });
  }, [applicantState]);

  useEffect(() => {
    if (applicantState !== APPLICANT_TYPES.recommended) {
      refetch({
        filters: filters || {
          name: keyword,
          missionId,
          missionRoleId,
          organizationId,
          isMember,
          published,
          ...stateFilter,
        },
      });
    }
  }, [keyword, missionId, missionRoleId, organizationId, isMember, stateFilter]);

  const getData = () => {
    if (applicantState === APPLICANT_TYPES.recommended) {
      /** We modify the data here to get user id's and state at the root level like other tabs. */
      return missionId || missionRoleId || organizationId
        ? data &&
            data[keyName]?.nodes?.map((item) => {
              return item.id ? item : { ...item, id: item?.profile?.id, state: applicantState };
            })
        : null;
    }
    return data && data[keyName]?.nodes;
  };

  const getError = () => {
    if (applicantState === APPLICANT_TYPES.recommended) {
      return missionId || missionRoleId || organizationId ? error : null;
    }
    return error;
  };

  const getAdvancedOptions = ({ id, missionRole }, selection) => {
    const type = APPLICANT_TYPE_VALUES.indexOf(applicantState);
    const ids = selection ? selection : [id];

    if (!ids.length || type < 0) return [];

    /**
     * Sets applicant to "unselected"
     */
    const removeOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToNotSelected" />,
      icon: ICON_TYPE.trash,
      onClick: () =>
        unselectedApplicant({
          variables: { input: { ids } },
          onCompleted: (data) => {
            const unselected = data?.unselectMissionRoleApplicants?.unselected || [];
            const failedToUnselect = data?.unselectMissionRoleApplicants?.failedToUnselect || [];
            handleToastMessages(
              unselected,
              failedToUnselect,
              "Missions.Applicants.ApplicantAdvancedOptions.SuccessNotSelected",
              "Missions.Applicants.ApplicantAdvancedOptions.Error",
              "Missions.Applicants.ApplicantAdvancedOptions.PartiallyNotSelected"
            );
          },
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "selected"
     */
    const selectedOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToSelected" />,
      icon: ICON_TYPE.checkmark,
      onClick: () =>
        selectedApplicant({
          variables: { input: { ids } },
          onCompleted: (data) => {
            const selected = data?.selectMissionRoleApplicants?.selected || [];
            const failedToSelect = data?.selectMissionRoleApplicants?.failedToSelect || [];
            handleToastMessages(
              selected,
              failedToSelect,
              "Missions.Applicants.ApplicantAdvancedOptions.SuccessSelected",
              "Missions.Applicants.ApplicantAdvancedOptions.Error",
              "Missions.Applicants.ApplicantAdvancedOptions.PartiallySelected"
            );
          },
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "interviewing"
     */
    const interviewingOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToInterviewing" />,
      icon: ICON_TYPE.video,
      onClick: () =>
        interviewApplicant({
          variables: { input: { ids } },
          onCompleted: (data) => {
            const interviewing = data?.interviewedMissionRoleApplicants?.interviewed || [];
            const failedToInterview = data?.interviewedMissionRoleApplicants?.failedToInterviewed || [];
            handleToastMessages(
              interviewing,
              failedToInterview,
              "Missions.Applicants.ApplicantAdvancedOptions.SuccessInterviewing",
              "Missions.Applicants.ApplicantAdvancedOptions.Error",
              "Missions.Applicants.ApplicantAdvancedOptions.PartiallyInterviewed"
            );
          },
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "shorlisted"
     */
    const shortListOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToShortlist" />,
      icon: ICON_TYPE.plus,
      onClick: () =>
        shortlistApplicant({
          variables: { input: { ids } },
          onCompleted: (data) => {
            const shortlisted = data?.shortlistMissionRoleApplicants?.shortlisted || [];
            const failedToShortlist = data?.shortlistMissionRoleApplicants?.failedToShortlist || [];
            handleToastMessages(
              shortlisted,
              failedToShortlist,
              "Missions.Applicants.ApplicantAdvancedOptions.SuccessShortlist",
              "Missions.Applicants.ApplicantAdvancedOptions.Error",
              "Missions.Applicants.ApplicantAdvancedOptions.PartiallyShortlisted"
            );
          },
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "invite"
     */
    const missionInviteMember = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToInvite" />,
      icon: ICON_TYPE.plus,
      onClick: () =>
        inviteApplicant({
          variables: {
            input: {
              missionRoleId: missionRole?.slug,
              profileIds: ids,
            },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessInvite" />),
          onError: () =>
            toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.ErrorInvite" />),
        }),
    };

    /**
     * Set applicant to "applied"
     */
    const appliedOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToApplicants" />,
      icon: ICON_TYPE.email,
      onClick: () =>
        moveApplicantToApplied({
          variables: { input: { ids } },
          onCompleted: (data) => {
            const applied = data?.moveMissionRoleApplicantsToApplied?.applied || [];
            const failedToApply = data?.moveMissionRoleApplicantsToApplied?.failedToApply || [];
            handleToastMessages(
              applied,
              failedToApply,
              "Missions.Applicants.ApplicantAdvancedOptions.SuccessApplicants",
              "Missions.Applicants.ApplicantAdvancedOptions.Error",
              "Missions.Applicants.ApplicantAdvancedOptions.PartiallyApplied"
            );
          },
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    switch (type) {
      // selected
      case 0:
        return selection ? [] : [interviewingOption, removeOption];

      // interviewing
      case 1:
        return selection ? [removeOption] : [selectedOption, shortListOption, removeOption];

      // shortlist
      case 2:
        return selection ? [removeOption] : [interviewingOption, appliedOption, removeOption];

      // applied
      case 3:
        return [shortListOption, removeOption];

      // not selected
      case 4:
        return [shortListOption];

      // Recommendation tab
      case 5:
        return [missionInviteMember];

      // for all other tabs
      default:
        return [];
    }
  };

  const handleToastMessages = (successList, failureList, successId, errorId, partialId = null) => {
    if (successList.length && !failureList.length) {
      toast.success(<FormattedMessage id={successId} />);
    } else if (successList.length && failureList.length) {
      toast.info(
        <FormattedMessage
          id={partialId || successId}
          values={{ successCount: successList.length, failedCount: failureList.length }}
        />
      );
    } else if (!successList.length && failureList.length) {
      toast.error(<FormattedMessage id={errorId} />);
    }
  };

  const getUpdateOptionsForApplicant = (state) => VALID_STATES_FOR_UPDATE[state] || [state];

  return {
    data: getData(),
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    error: getError(),
    refetch,
    getAdvancedOptions,
    getUpdateOptionsForApplicant,
    paginationType,
    resultsPerPage: data?.[keyName]?.perPage,
    count: {
      total: data?.[keyName]?.totalCount || 0,
      applied: data?.[keyName]?.appliedCount,
      interviewing: data?.[keyName]?.interviewingCount,
      invited: data?.[keyName]?.invitedCount,
      selected: data?.[keyName]?.selectedCount,
      shortlisted: data?.[keyName]?.shortlistedCount,
      unselected: data?.[keyName]?.unselectedCount,
      pending: data?.[keyName]?.pendingCount,
    },
    pageInfo: data?.[keyName]?.pageInfo,
    currentPage: data?.[keyName]?.currentPage,
  };
}

const VALID_STATES_FOR_UPDATE = {
  [APPLICANT_TYPES.pending]: [APPLICANT_TYPES.pending],
  [APPLICANT_TYPES.applied]: [APPLICANT_TYPES.applied, APPLICANT_TYPES.shortlisted, APPLICANT_TYPES.unselected],
  [APPLICANT_TYPES.shortlisted]: [
    APPLICANT_TYPES.shortlisted,
    APPLICANT_TYPES.interviewed,
    APPLICANT_TYPES.applied,
    APPLICANT_TYPES.unselected,
  ],
  [APPLICANT_TYPES.interviewed]: [
    APPLICANT_TYPES.interviewed,
    APPLICANT_TYPES.selected,
    APPLICANT_TYPES.shortlisted,
    APPLICANT_TYPES.unselected,
  ],
  [APPLICANT_TYPES.selected]: [APPLICANT_TYPES.selected, APPLICANT_TYPES.interviewed, APPLICANT_TYPES.unselected],
  [APPLICANT_TYPES.unselected]: [APPLICANT_TYPES.unselected, APPLICANT_TYPES.shortlisted],
};

const APPLICANT_TYPE_VALUES = [
  APPLICANT_TYPES.selected,
  APPLICANT_TYPES.interviewed,
  APPLICANT_TYPES.shortlisted,
  APPLICANT_TYPES.applied,
  APPLICANT_TYPES.unselected,
  APPLICANT_TYPES.recommended,
];

const QUERY_KEYNAMES = {
  applicants: "missionRoleApplicants",
  invitations: "missionRoleInvitations",
  recommendations: "missionRoleRecommendations",
};
