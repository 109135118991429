import { DECORATORS, SIZE, TYPE } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";
import { GET_ORGANIZATIONS, GET_PARTNERS } from "graphql/queries";

const defaultFormFields = [
  {
    label: "First Name",
    type: TYPE.input,
    size: SIZE.xlarge,
    properties: {
      name: "firstName",
      type: "text",
      maxLength: 100,
      required: true,
    },
  },
  {
    label: "Last Name",
    type: TYPE.input,
    size: SIZE.xlarge,
    properties: {
      name: "lastName",
      type: "text",
      maxLength: 100,
      required: true,
    },
  },
  {
    label: "Email",
    type: TYPE.input,
    size: SIZE.xlarge,
    properties: {
      name: "email",
      type: "email",
      maxLength: 100,
      required: true,
      placeholder: "Email",
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.email,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const USER_FORM_CONFIGS = {
  admin: () => [...defaultFormFields],
  client: ({ organizationId }) => [
    ...defaultFormFields,
    {
      label: "Organization",
      type: TYPE.select,
      size: SIZE.xlarge,
      disabled: !!organizationId,
      properties: {
        name: "organizationId",
        queryName: GET_ORGANIZATIONS,
        ...(organizationId && {
          queryVariables: {
            filters: {
              ids: [organizationId],
            },
          },
        }),
        queryKeyName: "organizations",
        labelKey: "name",
        valueKey: "id",
        isSearchable: true,
        querySearchFieldName: "search",
        required: true,
        noOptionsMessage: "No organizations found",
      },
    },
  ],
  recruiter: () => [...defaultFormFields],
  partner: () => [
    ...defaultFormFields,
    {
      label: "Partner",
      type: TYPE.select,
      size: SIZE.xlarge,
      properties: {
        name: "partnerId",
        queryName: GET_PARTNERS,
        queryKeyName: "partners",
        labelKey: "name",
        valueKey: "id",
        isSearchable: true,
        querySearchFieldName: "name",
        required: true,
        noOptionsMessage: "No partners found",
      },
    },
  ],
  showcase: () => [
    ...defaultFormFields,
    {
      type: TYPE.checkbox,
      size: SIZE.xlarge,
      properties: {
        type: "checkbox",
        name: "skipInvite",
        label: "Do not send an automated email",
      },
    },
  ],
  signal: () => [
    ...defaultFormFields,
    {
      label: "Organization",
      type: TYPE.select,
      size: SIZE.xlarge,
      properties: {
        name: "organizationId",
        queryName: GET_ORGANIZATIONS,
        queryKeyName: "organizations",
        labelKey: "name",
        valueKey: "id",
        isSearchable: true,
        querySearchFieldName: "search",
        required: true,
        noOptionsMessage: "No organizations found",
      },
    },
  ],
  showcaseToClient: () => [
    {
      label: "Organization",
      type: TYPE.select,
      size: SIZE.xlarge,
      properties: {
        name: "organizationId",
        queryName: GET_ORGANIZATIONS,
        queryKeyName: "organizations",
        labelKey: "name",
        valueKey: "id",
        isSearchable: true,
        querySearchFieldName: "search",
        required: true,
        noOptionsMessage: "No organizations found",
        placeholder: "Select Organization to assign",
      },
    },
  ],
};

export default USER_FORM_CONFIGS;
