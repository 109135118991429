import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer, Row } from "components/Containers";
import { breakpoints, colors, fontSize } from "style";
import { H1, MarketingHeader } from "components/Text";

/**
 * MissionRolesPublicHowItWorks
 */
const MissionRolesPublicHowItWorks = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid gap={`5rem`} rowGap={`5rem`} css={styles.inner_container}>
        <Grid.col start={4} end={10}>
          <Col gap="0" css={styles.title_container}>
            <MarketingHeader variant={colors.pinkPantherBase}>              
              <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.Header" />            
            </MarketingHeader>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.Title" />            
            </H1>
          </Col>
        </Grid.col>
        <BodyContent />
      </Grid>
    </NarrowContainer>
  </div>
);

const BodyContent = () => (
  <>
    <Grid.col start={1} end={9}>
      <iframe 
        css={styles.frame}
        src="https://www.youtube.com/embed/U-KG1hLuSgo"
        title="Vetting explained" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen 
      />
    </Grid.col>
    <Grid.col start={9} end={13} css={styles.point_container}>
      <Col gap="2rem">
        <Col css={styles.steps_title}>
          <span css={styles.subtitle}>
            <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.Signup1" />
          </span>
          <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.Signup2" />
        </Col>
        <BodyPoints />
      </Col>
    </Grid.col>        
  </>
);

const BodyPoints = () => (
  <>
    <Row css={styles.row}>
      <MarketingHeader variant={colors.pinkPantherBase}>01</MarketingHeader>
      <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.CreateProfile" />
    </Row>
    <Row css={styles.row}>
      <MarketingHeader variant={colors.pinkPantherBase}>02</MarketingHeader>
      <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.RecordVideo" />
    </Row>
    <Row css={styles.row}>
      <MarketingHeader variant={colors.pinkPantherBase}>03</MarketingHeader>
      <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.CompleteCodeChallenge" />
    </Row>
    <Row css={styles.row}>
      <MarketingHeader variant={colors.pinkPantherBase}>04</MarketingHeader>
      <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.CompleteApplicationForm" />
    </Row>
  </>
);

const styles = {
  container: css`
    color: ${colors.white};
    background: ${colors.purpleRainDark2};
    padding: 21rem 0 14rem 0;
    border-radius: 3.4rem;
    margin-top: -7rem;
    z-index:0;
    font-size: ${fontSize.medium};
    line-height: normal;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 12rem 0 4rem 0;
    }
  `,
  steps_title: css`
    gap:1rem;
    margin-bottom: 2rem;
  `,
  subtitle: css`
    font-weight:600;
    display:block;
  `,
  inner_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    width:100%;
    text-align:center;
  `,
  title: css`
    color: ${colors.white};
  `,
  list: css`
    ul {
      display:flex;
      flex-wrap:wrap;

      li {
        width:33.3333%;
      }
    }
  `,
  frame: css`
    background: #000;
    width: 100%;
    height:40rem;

    @media (max-width: ${breakpoints.mobile}) {
      height:17rem;
      margin-bottom: 2rem;
    }
  `,
  point_container: css`
    display:flex;
    align-items:center;

    @media (max-width: ${breakpoints.mobile}) {
      justify-content:center;
    }
  `,
  row: css`
    flex-direction: row;    
    gap: 2rem;
    flex-wrap: nowrap;
    align-items: flex-start;

    > *:first-of-type {
      display:block;
      width: 2rem;
    }   
  `
};

export { BodyContent, BodyPoints };

export default MissionRolesPublicHowItWorks;
