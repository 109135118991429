import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a form element by type
 *
 * @param {Object}     item
 * @param {Function}   onChange
 * @param {Object}     formData
 * @param {Boolean}    hasError
 * @param {Boolean}    disabled
 */
const FormElement = ({ item, onChange, hasError, formData, disabled, ...props }) => {
  const { valueFunc, onChange: itemOnChange, parentObjectKey, relatedValueFunc } = item;

  /**
   * Disable field based on value of another field
   */
  const isDisabled = () => {
    if (item.disabledCondition) {
      const { field, value, fieldParentKey } = item.disabledCondition;
      if (fieldParentKey) {
        return formData?.[fieldParentKey]?.[field] === value;
      } else {
        return formData?.[field] === value;
      }
    }

    return false;
  };

  /**
   * Allows the form item to intercept the onChange function and perform a custom side effect.
   */
  const handleChangeSwitcher = (name, value) => {
    if (typeof itemOnChange === "function") {
      itemOnChange(name, value, handleChange);
    } else {
      handleChange(name, value);
    }
  };

  /**
   * Sends change event to parent. Apply value function to value (if exists).
   *
   * If relatedValueFunc exists, then we need to check if the related state is disabled or if the related state value has changed.
   * If the related state is disabled, then we can update the value.
   * If the related state value has changed, then we need to update the value based on the related state value.
   */
  const handleRelatedValues = (name, value) => {
    relatedValueFunc?.forEach((config) => {
      const {
        relatedStateParentkey = parentObjectKey,
        relatedStateReason,
        relatedState,
        state,
        name,
        valueFunc,
      } = config;
      const parentObj = config.parentObjectKey || parentObjectKey;
      const relatedValue = formData?.[relatedState] || formData?.[relatedStateParentkey]?.[relatedState];

      const handleDisabledState = () => {
        const isRelatedStateDisabled = relatedState ? relatedValue === state : true;

        if (isRelatedStateDisabled) {
          onChange(name, valueFunc(value), parentObj);
        }
      };

      const handleValueChange = () => {
        onChange(name, valueFunc(relatedValue), parentObj);
      };

      switch (relatedStateReason) {
        case "disabled":
          handleDisabledState();
          break;
        case "valueChange":
          handleValueChange();
          break;
        default:
          console.error("Unsupported related state reason:", relatedStateReason);
      }
    });
  };

  /**
   * Handle form item onChange events
   */
  const handleChange = (name, value) => {
    handleRelatedValues(name, value);
    onChange(name, valueFunc ? valueFunc(value) : value, parentObjectKey);
  };

  /**
   * Set the value based on field name and parent object key name (if exists)
   */
  const getValue = () =>
    parentObjectKey ? formData?.[parentObjectKey]?.[item?.properties?.name] : formData?.[item?.properties?.name];

  return (
    <item.type
      {...item.properties}
      value={getValue()}
      hasError={hasError}
      onChange={handleChangeSwitcher}
      formData={formData}
      disabled={disabled || isDisabled()}
      {...props}
    />
  );
};

/**
 * Allows us to use a higher component to enhance functionality
 *
 * @params {Object}     decorator
 */
const ComposedElement = ({ decorator, ...props }) => {
  if (decorator) {
    return decorator.type(FormElement, decorator.props)(props);
  } else {
    return <FormElement {...props} />;
  }
};

ComposedElement.propTypes = {
  decorator: PropTypes.object,
};

FormElement.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  formData: PropTypes.object,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ComposedElement;
