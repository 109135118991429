/** @jsx jsx */
import styled from "@emotion/styled";
import { colors } from "style";

/**
 * FooterTableRow
 */
const FooterTableRow = styled.tr`
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &:first-of-type {
  }

  &:last-of-type {
    td {
      border-top: 1px ${colors.grayAnatomyLight4} solid;
      &:nth-of-type(1) {
        border-top: none;
      }
      &:nth-of-type(2) {
        border-top: none;
      }
    }
  }
`;

export default FooterTableRow;
