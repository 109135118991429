const MISSION_ROLE_ELIGIBILITY = {
  not_published: "NOT_PUBLISHED",
  no_user: "NO_USER",
  already_applied: "ALREADY_APPLIED",
  has_pending_application: "HAS_PENDING_APPLICATION",
  redirect_to_role: "REDIRECT_TO_ROLE",
  create_application: "CREATE_APPLICATION",
  rejected: "REJECTED",
};

export default MISSION_ROLE_ELIGIBILITY;
