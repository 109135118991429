import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { LoginContainer } from "components/Login";
import SignupForms from "components/Signup/SignupForms";
import backgroundNormal from "assets/images/Faith-2000x1340-c-center.jpg";
import backgroundRole from "assets/images/Faith-2000x1340-c-center-v2.jpg";

/**
 * Login
 */
const Signup = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const isRoleApplication = searchParams.get("role") !== null;
  const [signupCompleted, setSignupCompleted] = useState(false);

  console.log("The signup is complete ", signupCompleted);
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Signup.Title" })}</title>
      </Helmet>
      <LoginContainer
        banner={bannerStyle(isRoleApplication)}
        isRoleApplication={isRoleApplication}
        signupCompleted={signupCompleted}
      >
        <SignupForms onSignupCompleted={setSignupCompleted} />
      </LoginContainer>
    </>
  );
};

const bannerStyle = (isRoleApplication) => ({
  title: "Gain access to exclusive opportunities and a network of top professionals.",
  label: "Faith Adekogbe",
  description: "Frontend Developer",
  cover: isRoleApplication ? `url(${backgroundRole})` : `url(${backgroundNormal})`,
});

export default Signup;
