import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Card from "components/Card";
import { Label, Select } from "components/Form";
import { Grid } from "components/Containers";
import { APPLICANT_LIST_SORTING_OPTIONS, DIRECTION } from "constants/index";
import { toUcFirst } from "utils";

/**
 * ApplicantListControls
 *
 * @param {Boolean}   hideMemberFilter
 * @param {Object}    selected
 * @param {Function}  setSelected
 */
const ApplicantListControls = ({ hideMemberFilter, selected, setSelected }) => (
  <Card>
    <Grid>
      {!hideMemberFilter && (
        <Grid.col colSpan={3}>
          <Label>
            <FormattedMessage id="Missions.Applicants.ApplicantListControls.MemberFilter.Label" />
          </Label>
          <Select
            value={selected?.isMember}
            placeholder="Profile Status"
            onChange={(_, val) => setSelected((prev) => ({ ...prev, isMember: val }))}
            valueKey="id"
            labelKey="name"
            options={[
              { name: "Member", id: true },
              { name: "Candidate", id: false },
            ]}
            isClearable
          />
        </Grid.col>
      )}
      <Grid.col colSpan={3}>
        <Label>
          <FormattedMessage id="Global.SortBy" />
        </Label>
        <Select
          name="sortKey"
          value={selected?.sortKey}
          options={APPLICANT_LIST_SORTING_OPTIONS}
          onChange={(name, val) => setSelected((prev) => ({ ...prev, [name]: val }))}
          isClearable={false}
        />
      </Grid.col>
      <Grid.col colSpan={3}>
        <Label>
          <FormattedMessage id="Global.SortDirection" />
        </Label>
        <Select
          name="sortDirection"
          value={selected?.sortDirection}
          options={Object.entries(DIRECTION).map(([label, value]) => ({ label: toUcFirst(label), value }))}
          onChange={(name, val) => setSelected((prev) => ({ ...prev, [name]: val }))}
          isClearable={false}
        />
      </Grid.col>
    </Grid>
  </Card>
);

ApplicantListControls.propTypes = {
  hideMemberFilter: PropTypes.bool,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

export default ApplicantListControls;
