import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import {
  ApplicantList,
  ApplicantListTabs,
  ApplicantListProgressMenu,
  ApplicantListFilters,
  ApplicantShareButton,
  ApplicantListControls,
} from "components/Missions/Applicants";
import Card from "components/Card";
import { useApplicants, useAuth, useParamFunctions } from "hooks";
import { APPLICANT_LIST_SORTING_OPTIONS, APPLICANT_TYPES, DIRECTION } from "constants/index";
import { Grid } from "components/Containers";

/**
 * ApplicantListLayout
 *
 * @param {Boolean} showOnlyUnpublishedRoles
 * @param {Boolean} showAdminOnlyPages
 * @param {Boolean} initialTab
 * @param {Boolean} initialProgressMenu
 * @param {Boolean} hideFilters
 * @param {Boolean} hideSorts
 * @param {Boolean} hideTabs
 * @param {Boolean} hideProgressMenu
 * @param {Boolean} hideOrganizationFilter
 * @param {Boolean} hideMissionFilter
 * @param {Boolean} hidePublishedFilter
 * @param {Boolean} hideMemberFilter
 * @param {Object}  defaultSelected
 * @param {Function} onRowClick
 * @param {Object} filtersProp
 * @param {Object} variablesProp
 */
const ApplicantListLayout = ({
  showOnlyUnpublishedRoles,
  showAdminOnlyPages,
  initialTab,
  initialProgressMenu,
  hideFilters,
  hideSorts,
  hideTabs,
  hideProgressMenu,
  hideOrganizationFilter,
  hideMissionFilter,
  hidePublishedFilter,
  hideMemberFilter,
  defaultSelected,
  onRowClick,
  filtersProp,
  variablesProp,
  ...props
}) => {
  const { canViewUnpublishedRoles, roles } = useAuth();
  const { paramParse, setParams } = useParamFunctions();
  const filters = paramParse(KEYS.filters);
  const sorts = paramParse(KEYS.sorts);
  const location = useLocation();
  const isPubFilter = filters?.[KEYS.isPublished];
  const locState = location.state;
  const [selectedProgressMenu, setSelectedProgressMenu] = useState(String(filters?.[KEYS.tab] ?? initialProgressMenu));
  const [selectedTab, setSelectedTab] = useState(initialTab || filters?.[KEYS.page] || 0);
  const pageTabs = PAGES.filter((item) => (showAdminOnlyPages && item.adminOnly) || !item.adminOnly);
  const applicantTabs = filterTabsByAdmin(TABS, showAdminOnlyPages);
  const [isPublished, setIsPublished] = useState(isPubFilter === undefined ? !canViewUnpublishedRoles : !!isPubFilter);

  const [selected, setSelected] = useState({
    missionId: filters?.[KEYS.missionId] ?? locState?.missionId ?? defaultSelected?.missionId,
    missionRoleId: filters?.[KEYS.missionRoleId] ?? locState?.missionRoleId ?? defaultSelected?.missionRoleId,
    organizationId: filters?.[KEYS.organizationId] ?? locState?.organizationId ?? defaultSelected?.organizationId,
    isMember: filters?.[KEYS.isMember] ?? locState?.isMember ?? defaultSelected?.isMember,
    applicantState: filters?.[KEYS.applicantState] ?? defaultSelected?.applicantState ?? APPLICANT_TYPES.applied,
    sortKey: sorts?.[KEYS.sortKey] ?? APPLICANT_LIST_SORTING_OPTIONS[0]?.value,
    sortDirection: sorts?.[KEYS.sortDirection] ?? DIRECTION.descending,
  });

  const { data, error, count, paginationType, resultsPerPage, loading, ...rest } = useApplicants({
    ...selected,
    published: showOnlyUnpublishedRoles ? false : isPublished, // Show unpublished roles for showcase and client
    resultsPerPage,
    filters: filtersProp,
    variables: variablesProp,
  });

  /**
   * @description Change the applicant state when the selected tab changes
   */
  useEffect(() => {
    if (hideTabs && hideProgressMenu) return;

    const [index, subIndex] = selectedProgressMenu.split("-").map(Number);

    const selectedItem =
      selectedTab === 0
        ? Array.isArray(applicantTabs[index])
          ? applicantTabs[index]?.[subIndex]
          : applicantTabs[index]
        : pageTabs[selectedTab];

    const newState = selectedItem?.type;

    if (selected.applicantState !== newState) {
      setSelected((prev) => ({ ...prev, applicantState: newState }));
    }
  }, [selectedTab, selectedProgressMenu, pageTabs, applicantTabs, hideTabs, hideProgressMenu]);

  /**
   * @description Update the URL params when data changes
   */
  useEffect(() => {
    setParams(KEYS.filters, {
      [KEYS.missionId]: selected?.missionId,
      [KEYS.missionRoleId]: selected?.missionRoleId,
      [KEYS.organizationId]: selected?.organizationId,
      [KEYS.isMember]: selected?.isMember,
      [KEYS.isPublished]: isPublished,
      [KEYS.tab]: selectedProgressMenu,
      [KEYS.applicantState]: selected?.applicantState,
      [KEYS.page]: selectedTab,
    });

    setParams(KEYS.sorts, {
      [KEYS.sortKey]: selected?.sortKey,
      [KEYS.sortDirection]: selected?.sortDirection,
    });

    // Reset to page 1 on filter
    if (count?.total) {
      rest?.handlePageChange(1, resultsPerPage);
    }
  }, [selected, isPublished, selectedProgressMenu, selectedTab, count?.total]);

  return (
    <Grid>
      {!hideFilters && (
        <Grid.col start={1} end={13}>
          <ApplicantListFilters
            hideOrganizationFilter={hideOrganizationFilter}
            hideMissionFilter={hideMissionFilter}
            hidePublishedFilter={hidePublishedFilter}
            hideMemberFilter={hideMemberFilter || !selected?.applicantState}
            {...{
              selected,
              setSelected,
              isPublished,
              setIsPublished,
            }}
          />
        </Grid.col>
      )}
      {!hideSorts && (
        <Grid.col start={1} end={13}>
          <ApplicantListControls selected={selected} setSelected={setSelected} hideMemberFilter={hideMemberFilter} />
        </Grid.col>
      )}
      <Grid.col start={1} end={13}>
        <Card>
          <Grid>
            {!hideTabs && (
              <Grid.col start={1} end={13}>
                <ApplicantListTabs
                  {...{
                    tabs: hideProgressMenu ? [] : pageTabs,
                    selectedTab,
                    setSelectedTab,
                  }}
                />
              </Grid.col>
            )}
            {!hideProgressMenu && selectedTab === 0 && (
              <Grid.col start={1} end={13}>
                <ApplicantListProgressMenu
                  {...{
                    applicantTabs,
                    count,
                    loading,
                    selectedProgressMenu,
                    setSelectedProgressMenu,
                  }}
                />
              </Grid.col>
            )}
            <Grid.col start={1} end={13}>
              <ApplicantList
                {...{
                  roles,
                  data,
                  error,
                  resultsPerPage,
                  paginationType,
                  loading,
                  selectedState: selected,
                  totalCount: count?.total,
                  onClick: onRowClick,
                  ...rest,
                  ...props,
                }}
              />
            </Grid.col>
          </Grid>
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <ApplicantShareButton
          missionRoleSlug={data && data[0]?.missionRole?.slug}
          applicantType={selected?.applicantState}
          missionId={selected?.missionId}
          missionRoleId={selected?.missionRoleId}
        />
      </Grid.col>
    </Grid>
  );
};

const KEYS = {
  filters: "f",
  missionId: "missionId",
  missionRoleId: "missionRoleId",
  organizationId: "organizationId",
  isPublished: "isPublished",
  isMember: "isMember",
  applicantState: "applicantState",
  page: "page",
  tab: "tab",
  sorts: "s",
  sortKey: "sortKey",
  sortDirection: "sortDirection",
};

const PAGES = [
  {
    title: "Missions.Applicants.TabTitleApplicants",
  },
  {
    title: "Missions.Applicants.TabTitleRecommended",
    type: APPLICANT_TYPES.recommended,
  },
];

const TABS = [
  [
    {
      title: "Missions.Applicants.TabTitleInvited",
      adminOnly: true,
      type: null,
      countField: "invited",
    },
    {
      title: "Missions.Applicants.TabTitlePending",
      adminOnly: true,
      type: APPLICANT_TYPES.pending,
      countField: "pending",
    },
  ],
  {
    title: "Missions.Applicants.TabTitleApplied",
    type: APPLICANT_TYPES.applied,
    countField: "applied",
  },
  {
    title: "Missions.Applicants.TabTitleShortlist",
    type: APPLICANT_TYPES.shortlisted,
    countField: "shortlisted",
  },
  {
    title: "Missions.Applicants.TabTitleInterviewing",
    type: APPLICANT_TYPES.interviewed,
    countField: "interviewing",
  },
  [
    {
      title: "Missions.Applicants.TabTitleSelected",
      type: APPLICANT_TYPES.selected,
      countField: "selected",
    },
    {
      title: "Missions.Applicants.TabTitleUnSelected",
      type: APPLICANT_TYPES.unselected,
      countField: "unselected",
    },
  ],
];

const filterTabsByAdmin = (tabs, isAdmin) =>
  tabs
    .map((item) => {
      if (Array.isArray(item)) {
        const filteredSubTabs = filterTabsByAdmin(item, isAdmin);
        return filteredSubTabs.length > 0 ? filteredSubTabs : null; // Remove empty arrays
      }
      return isAdmin || !item.adminOnly ? item : null;
    })
    .filter(Boolean); // Remove null values

ApplicantListLayout.defaultProps = {
  initialTab: 0,
  initialProgressMenu: 1,
  hideSorts: true,
};

ApplicantListLayout.propTypes = {
  showOnlyUnpublishedRoles: PropTypes.bool,
  showAdminOnlyPages: PropTypes.bool,
  initialTab: PropTypes.number,
  initialProgressMenu: PropTypes.number,
  hideFilters: PropTypes.bool,
  hideSorts: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideProgressMenu: PropTypes.bool,
  hideOrganizationFilter: PropTypes.bool,
  hideMissionFilter: PropTypes.bool,
  hidePublishedFilter: PropTypes.bool,
  hideMemberFilter: PropTypes.bool,
  defaultSelected: PropTypes.object,
  onRowClick: PropTypes.func,
  filtersProp: PropTypes.object,
  variablesProp: PropTypes.object,
};

export default ApplicantListLayout;
