import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { PopoverCard } from "components/ActivityLog";
import ListTable from "components/ListTable";
import { formatNumber } from "utils";

/**
 * RateScoreDisplay
 *
 * @params {Object}    rateData
 * @params {String}    currency
 */
const RateScoreDisplay = ({ rateData, currency }) => {
  if (!rateData || !currency) return <FormattedMessage id="Global.NA" />;

  return (
    <PopoverCard width="35rem" paddingBottom>
      <ListTable data={TABLE_DATA(rateData, currency)} />
    </PopoverCard>
  );
};

const TABLE_DATA = (
  {
    missionRoleTargetRateUsd,
    profileHourlyRateUsd,
    minimumApplicantHourlyRateUsd,
    minimumAssignedHourlyRateUsd,
    consideredHourlyRateUsd,
    negotiatedHourlyRateUsd,
    applicantHourlyRateUsd,
  },
  currency
) =>
  [
    {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.TargetRate" />,
      value: (
        <FormattedMessage id="Global.HourlyRateWithCurrency" values={{ rate: missionRoleTargetRateUsd, currency }} />
      ),
    },
    {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.PreferredRate" />,
      value: (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(profileHourlyRateUsd, "", 2), currency }}
        />
      ),
    },
    {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.MinApplicationRate" />,
      value: minimumApplicantHourlyRateUsd ? (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(minimumApplicantHourlyRateUsd, "", 2), currency }}
        />
      ) : (
        "-"
      ),
    },
    {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.MinAssignedRate" />,
      value: (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(minimumAssignedHourlyRateUsd, "", 2), currency: currency }}
        />
      ),
    },
    applicantHourlyRateUsd && {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.ApplicantRate" />,
      value: (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(applicantHourlyRateUsd, "", 2), currency: currency }}
        />
      ),
    },
    negotiatedHourlyRateUsd && {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.NegotiatedRate" />,
      value: (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(negotiatedHourlyRateUsd, "", 2), currency: currency }}
        />
      ),
    },
    {
      label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.ConsideredRate" />,
      value: (
        <FormattedMessage
          id="Global.HourlyRateWithCurrency"
          values={{ rate: formatNumber(consideredHourlyRateUsd, "", 2), currency: currency }}
        />
      ),
    },
  ].filter(Boolean);

RateScoreDisplay.propTypes = {
  rateData: PropTypes.object.isRequired,
  currency: PropTypes.string,
};

export default RateScoreDisplay;
