import React from "react";
import PropTypes from "prop-types";
import { PopoverCard } from "components/ActivityLog";
import ApplicantProgress from "./ApplicantProgress";
import { RENDER_SIZE } from "components/DisplayBox";

/**
 * ApplicantChecklistDisplay
 *
 * @param {Object} checklist
 */
const ApplicantChecklistDisplay = ({ missionRoleApplicant }) => {
  return (
    <PopoverCard width="30rem">
      <ApplicantProgress
        missionRoleApplicant={missionRoleApplicant}
        size={RENDER_SIZE.xsmall}
        hideSubmitButton
        hideHeader
      />
    </PopoverCard>
  );
};

ApplicantChecklistDisplay.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
};

export default ApplicantChecklistDisplay;
