import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "hooks";
import { BANNER_TYPE } from "constants/index";
import { Row } from "components/Containers";
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize, margins } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * Banner
 *
 * @param {Element}   children
 * @param {String}    keyName
 * @param {String}    marginSize
 * @param {Boolean}   isClosable
 * @param {String}    size
 * @param {String}    type
 * @param {Function}  cta
 */
const Banner = ({ children, keyName, marginSize, isClosable, size, type, cta, ...props }) => {
  const { preferences, updatePreferences, isClient } = useAuth();
  const [keyValue, setKeyValue] = useState(preferences?.[keyName] !== false);
  const handleClose = () => {
    setKeyValue(false);
    updatePreferences({ keyName, keyValue: false });
  };

  if (!keyValue) return null;

  return (
    <StyledRow {...props} marginSize={marginSize} type={type} size={size} addBorder={isClient}>
      <div>{children}</div>
      {typeof cta === "function" && <CtaContainer>{cta?.()}</CtaContainer>}
      {isClosable && (
        <div>
          <IconContainer type={type} onClick={handleClose}>
            <Icon type={ICON_TYPE.close} size={ICON_SIZE.medium} color={colors.white} />
          </IconContainer>
        </div>
      )}
    </StyledRow>
  );
};

const bannerColors = {
  [BANNER_TYPE.info]: {
    background: colors.lightGreen,
    text: colors.darkGreen,
    icon: colors.green,
  },
  [BANNER_TYPE.warning]: {
    background: colors.lightYellow,
    text: colors.black,
    icon: colors.yellow,
  },
  [BANNER_TYPE.error]: {
    background: colors.lightRed,
    text: colors.black,
    icon: colors.red,
  },
};

const BANNER_SIZE = {
  small: "small",
  normal: "normal",
};

const StyledRow = styled(Row)`
  background: ${({ type }) => bannerColors[type].background};
  color: ${({ type }) => bannerColors[type].text};
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 3rem;
  line-height: 1.3;
  ${({ addBorder }) => addBorder && "margin-top: 2rem"};

  ${({ size }) =>
    size === BANNER_SIZE.small &&
    `
    padding: 0 1rem;
    line-height: normal;
    font-size: 1.3rem;
    margin-left: auto;
    height:3.5rem;
  `}

  @media (max-width: ${breakpoints.portrait}) {
    padding: 1rem 2.5rem;
    font-size: ${fontSize.xsmall};
    gap: 1rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem 2rem;
  }
`;

const IconContainer = styled.div`
  border-radius: 100%;
  background: ${({ type }) => bannerColors[type].icon};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;

  /* Fixed alignement issue with x icon */
  > * {
    top: 0px !important;
  }
`;

const CtaContainer = styled.div`
  margin-left: auto;
  white-space: nowrap;
`;

Banner.propTypes = {
  children: PropTypes.element.isRequired,
  keyName: PropTypes.string.isRequired,
  marginSize: PropTypes.string,
  isClosable: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  cta: PropTypes.function,
};

Banner.defaultProps = {
  marginSize: margins.normal,
  isClosable: true,
  type: BANNER_TYPE.info,
  size: BANNER_SIZE.normal,
};

export { BANNER_SIZE };

export default Banner;
