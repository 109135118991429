import React from "react";
import PropTypes from "prop-types";
import { Routes as ReactRouterRoutes, Route, Navigate } from "react-router-dom";
import Candidate from "pages/candidate";
import CandidateHome from "pages/candidate/home";
import CandidateProfile from "pages/candidate/profile";
import Mission from "pages/missions/mission";
import MissionProvider from "pages/missions/mission/provider";
import Missions from "pages/missions";
import MissionsHome from "pages/missions/home";
import MissionsApplicants from "pages/missions/applicants";
import MissionsApplications from "pages/missions/applications";
import MissionsApplication from "pages/missions/applications/[id]";
import MissionBoard from "pages/missions/board";
import MissionNew from "pages/missions/new";
import MissionEdit from "pages/missions/mission/edit";
import Signup from "pages/signup";
import ClientSignup from "pages/clientSignup";
import SignalSignup from "pages/signal_signup";
import ProfileProvider from "pages/profiles/provider";
import Profiles from "pages/profiles";
import Rate from "pages/profiles/rate";
import Timeline from "pages/profiles/timeline";
import Notes from "pages/profiles/notes";
import ProfileVideoInterviews from "pages/profiles/video_interviews";
import ProfileSettings from "pages/profiles/settings";
import Partners from "pages/partners";
import PublicProfile from "pages/PublicProfile";
import Privacy from "pages/legal/privacy";
import TermsAndConditions from "pages/legal/termsAndConditions";
import Home from "pages/home";
import TimeCard from "pages/TimeCard";
import Timesheet from "pages/timesheet";
import Signin from "pages/signin";
import ForgotPassword from "pages/forgot_password";
import ResetPassword from "pages/reset_password";
import Dashboard from "pages/dashboard";
import DashboardTeamView from "pages/dashboard/[id]";
import ClientFeedback from "pages/squads/squad/client_feedback";
import MissionProposal from "pages/missions/mission/proposal";
import Squads from "pages/squads";
import SquadNew from "pages/squads/new";
import SquadsHome from "pages/squads/home";
import SquadProfile from "pages/squads/squad/profile";
import SquadPulse from "pages/squads/squad/pulse";
import EffortReport from "pages/squads/squad/effort_report";
import SquadSettings from "pages/squads/squad/settings";
import Engineering from "pages/squads/squad/engineering";
import EngineeringCodeTelemetry from "pages/squads/squad/engineering/code_telemetry";
import DailyReport from "pages/squads/squad/daily_report";
import MissionReport from "pages/squads/squad/mission_report";
import SquadActivityLog from "pages/squads/squad/engineering/activity_log";
import SquadLeaderboard from "pages/squads/squad/engineering/all_time_activity";
import TermSheet from "pages/squads/squad/term_sheet";
import SettingsCodeChallenges from "pages/settings/code_challenges";
import PlatformAccess from "pages/settings/platform_access";
import Prompts from "pages/settings/prompts";
import MissionEvents from "pages/settings/mission_events";
import SideNav from "components/Nav/SideNav";
import Network from "pages/network";
import Organizations from "pages/organizations";
import Lists from "pages/lists";
import List from "pages/lists/[slug]";
import PublicList from "pages/publicLists/[slug]";
import { AuthProvider, useAuth, useViewport } from "hooks";
import { LINKS } from "constants/index";
import DashboardTeamActivityLog from "pages/dashboard/[id]/activity_log";
import ClientHome from "pages/client/home";
import ClientCommunity from "pages/client/community";
import ClientApplications from "pages/client/applicants";
import Favorites from "pages/client/favorites";
import Favorite from "pages/client/favorites/[slug]";
import ClientTalentSearch from "pages/client/search";
import ClientTalentTeamBuilder from "pages/client/team_builder";
import ClientProfile from "pages/client/profile";
import ClientMissionBoard from "pages/client/mission_board";
import PublicMissionRole from "pages/missions/mission/public";
import TermSheets from "pages/squads/squad/term_sheet";
import BookMeeting from "pages/client/book_meeting";
import FeaturedTeams from "pages/client/featured_teams";
import PlatformSettings from "pages/settings";
import FeaturedTeamProfiles from "pages/client/featured_teams/[slug]";
import OrganizationProfile from "pages/organizations/profile";
import MobileMessage from "components/MobileMessage";
import ShowcaseSignup from "pages/showcaseSignup";
import SquadInsights from "pages/squads/squad/insights";
import LegalModal from "pages/legal/modal";
import Signal from "pages/squads/squad/settings/signal";
import SignalSettings from "pages/squads/squad/settings/signal_settings";
import DashboardActivityLogs from "pages/dashboard/activity_logs";
import Overview from "pages/dashboard/overview";
import ProfileCodeChallenges from "pages/profiles/code_challenges";
import ProfileApplications from "pages/profiles/applications";
import ProfileAssignments from "pages/profiles/assignments";
import DailyReportSettings from "pages/squads/squad/settings/daily_report_settings";
import MissionRole from "pages/missions/mission/role";
import MissionRoleDetails from "pages/missions/mission/role/details";
import MissionRoleRecommendations from "pages/missions/mission/role/recommendations";
import MissionRoleApplications from "pages/missions/mission/role/applications";

/**
 * Routes
 */
const Routes = () => (
  <ReactRouterRoutes>
    <Route path={LINKS.signup} element={<Signup />} />
    <Route path={LINKS.client_signup} element={<ClientSignup />} />
    <Route path={LINKS.signal_signup} element={<SignalSignup />} />
    <Route path={LINKS.showcase_signup} element={<ShowcaseSignup />} />
    <Route path={LINKS.login} element={<Signin />} />
    <Route path={LINKS.forgot_password} element={<ForgotPassword />} />
    <Route path={LINKS.reset_password(`:id`)} element={<ResetPassword />} />
    <Route path={LINKS.public_profile(`:id`)} element={<PublicProfile />} />
    <Route path={LINKS.public_mission_role(`:missionId`, `:id`)} element={<PublicMissionRole />} />
    <Route path={LINKS.privacy} element={<Privacy />} />
    <Route path={LINKS.terms_and_conditions} element={<TermsAndConditions />} />
    <Route path={LINKS.mission_url(`:id`)} element={<MissionProposal />} />
    <Route path={LINKS.public_list(`:id`)} element={<PublicList />} />
    <Route path="/" element={<AuthProvider />}>
      <Route path="/" element={<LegalModal />}>
        <Route path={LINKS.candidate} element={<Candidate />}>
          <Route path={LINKS.candidate_home} element={<CandidateHome />} />
          <Route path={LINKS.candidate_profile} element={<CandidateProfile />} />
        </Route>
        <Route path="/" element={<SideNav />}>
          <Route
            path={LINKS.client_home}
            element={<ProtectedAdminRoute element={<ClientHome />} byPassFields={[`isClient`, `isShowcase`]} />}
          />
          <Route
            path={LINKS.client_search}
            element={
              <ProtectedAdminRoute
                element={<ClientTalentSearch />}
                byPassFields={[`isClient`, `isShowcase`, `isSignalClient`]}
              />
            }
          />
          <Route
            path={LINKS.favorites}
            element={<ProtectedAdminRoute element={<Favorites />} byPassFields={[`isClient`, `isShowcase`]} />}
          />
          <Route
            path={LINKS.favorite(`:id`)}
            element={<ProtectedAdminRoute element={<Favorite />} byPassFields={[`isClient`, `isShowcase`]} />}
          />
          <Route
            path={LINKS.client_team_builder}
            element={
              <ProtectedAdminRoute element={<ClientTalentTeamBuilder />} byPassFields={[`isClient`, `isShowcase`]} />
            }
          />
          <Route
            path={LINKS.featured_teams}
            element={<ProtectedAdminRoute element={<FeaturedTeams />} byPassFields={[`isClient`, `isShowcase`]} />}
          />
          <Route
            path={LINKS.featured_team(`:slug`)}
            element={
              <ProtectedAdminRoute element={<FeaturedTeamProfiles />} byPassFields={[`isClient`, `isShowcase`]} />
            }
          />
          <Route path={LINKS.community} element={<ClientCommunity />} />
          <Route
            path={LINKS.client_applications}
            element={
              <DesktopRoute
                element={
                  <ProtectedAdminRoute element={<ClientApplications />} byPassFields={[`isClient`, `isShowcase`]} />
                }
              />
            }
          />
          <Route
            path={LINKS.client_mission_board}
            element={<ProtectedAdminRoute element={<ClientMissionBoard />} byPassFields={[`isClient`, `isShowcase`]} />}
          />
          <Route
            path={LINKS.client_profile}
            element={<ProtectedAdminRoute element={<ClientProfile />} byPassFields={[`isClient`]} />}
          />
          <Route path={LINKS.book_meeting_link} element={<BookMeeting />} />
          <Route path={LINKS.home} element={<Home />} />
          <Route path={LINKS.missions} element={<Missions />}>
            <Route
              path={LINKS.missions}
              element={<ProtectedAdminRoute element={<MissionsHome />} navigateTo="board" />}
            />
            <Route path={LINKS.mission_board} element={<MissionBoard />} />
            <Route
              path={LINKS.mission_applicants}
              element={
                <DesktopRoute
                  element={
                    <ProtectedAdminRoute
                      element={<MissionsApplicants />}
                      navigateTo={LINKS.missions}
                      byPassFields={[`isClient`]}
                    />
                  }
                />
              }
            />
            <Route
              path={LINKS.mission_create}
              element={<ProtectedAdminRoute element={<MissionNew />} navigateTo={LINKS.missions} />}
            />
          </Route>
          <Route path={LINKS.mission_applications} element={<MissionsApplications />} />
          <Route path={LINKS.mission_application(`:id`)} element={<MissionsApplication />} />
          <Route path={LINKS.mission(`:id`)} element={<MissionProvider />}>
            <Route path={LINKS.mission(`:id`)} element={<Mission />} />
            <Route path={LINKS.mission_role(`:id`, `:roleId`)} element={<MissionRole />}>
              <Route path={LINKS.mission_role(`:id`, `:roleId`)} element={<Navigate to="details" />} />
              <Route path={LINKS.mission_role_details(`:id`, `:roleId`)} element={<MissionRoleDetails />} />
              <Route
                path={LINKS.mission_role_recommendations(`:id`, `:roleId`)}
                element={<MissionRoleRecommendations />}
              />
              <Route path={LINKS.mission_role_applications(`:id`, `:roleId`)} element={<MissionRoleApplications />} />
            </Route>
            <Route path={LINKS.mission_edit(`:id`)} element={<ProtectedAdminRoute element={<MissionEdit />} />} />
          </Route>
          <Route path={LINKS.squads} element={<DesktopRoute element={<Squads />} />}>
            <Route path={LINKS.squads} element={<ProtectedAdminRoute element={<SquadsHome />} />} />
            <Route path={LINKS.squad(`:id`)} element={<Navigate to="profile" />} />
            <Route path={LINKS.squad_profile(`:id`)} element={<SquadProfile />} />
            <Route path={LINKS.squad_effort_report(`:id`)} element={<EffortReport />} />
            <Route path={LINKS.squad_pulse(`:id`)} element={<SquadPulse />} />
            <Route path={LINKS.squad_engineering(`:id`)} element={<Engineering />}>
              <Route path={LINKS.squad_engineering(`:id`)} element={<Navigate to="code_telemetry" />} />
              <Route path={LINKS.squad_engineering_code_telemetry(`:id`)} element={<EngineeringCodeTelemetry />} />
              <Route
                path={LINKS.squad_engineering_code_telemetry_individual(`:id`, `:uuid`)}
                element={<EngineeringCodeTelemetry />}
              />
              <Route path={LINKS.squad_activity_report(`:id`)} element={<SquadActivityLog />} />
              <Route path={LINKS.squad_activity_all_time(`:id`)} element={<SquadLeaderboard />} />
            </Route>
            <Route path={LINKS.squad_daily_report(`:id`)} element={<DailyReport />} />
            <Route path={LINKS.member_mission_report(`:id`)} element={<MissionReport />} />
            <Route path={LINKS.squad_settings(`:id`)} element={<SquadSettings />}>
              <Route path={LINKS.squad_settings(`:id`)} element={<Navigate to={"signal_installation"} />} />
              <Route path={LINKS.squad_daily_report_settings(`:id`)} element={<DailyReportSettings />} />
              <Route path={LINKS.squad_engineering_metrics_settings(`:id`)} element={<SignalSettings />} />
              <Route path={LINKS.squad_signal_installation_settings(`:id`)} element={<Signal />} />
            </Route>
            <Route path={LINKS.squad_activity_log(`:id`)} element={<SquadActivityLog />} />
            <Route path={LINKS.squad_term_sheet(`:id`)} element={<TermSheet />} />
            <Route path={LINKS.admin_squads_feedback(`:id`)} element={<ClientFeedback />} />
            <Route path={LINKS.squad_create} element={<SquadNew />} />
            <Route path={LINKS.squad_insights(`:id`)} element={<SquadInsights />} />
          </Route>
          <Route path={LINKS.network} element={<Network />} />
          <Route path={LINKS.private_profile(`:id`)} element={<ProfileProvider />}>
            <Route path={LINKS.private_profile(`:id`)} element={<Profiles />} />
            <Route
              path={LINKS.profile_code_challenges(`:id`)}
              element={<ProtectedAdminRoute element={<ProfileCodeChallenges />} />}
            />
            <Route
              path={LINKS.profile_applications(`:id`)}
              element={<ProtectedAdminRoute element={<ProfileApplications />} />}
            />
            <Route
              path={LINKS.profile_assignments(`:id`)}
              element={<ProtectedAdminRoute element={<ProfileAssignments />} />}
            />
            <Route path={LINKS.profile_video_interviews(`:id`)} element={<ProfileVideoInterviews />} />
            <Route path={LINKS.rate(`:id`)} element={<Rate />} />
            <Route path={LINKS.timeline(`:id`)} element={<ProtectedAdminRoute element={<Timeline />} />} />
            <Route path={LINKS.notes(`:id`)} element={<Notes />} />
            <Route path={LINKS.settings(`:id`)} element={<ProfileSettings />} />
          </Route>
          <Route path={LINKS.timecards} element={<TimeCard />} />
          <Route path={LINKS.time_sheet} element={<DesktopRoute element={<Timesheet />} />} />
          <Route path={LINKS.partners} element={<ProtectedAdminRoute element={<Partners />} />} />
          <Route path={LINKS.platform_settings} element={<ProtectedAdminRoute element={<PlatformSettings />} />}>
            <Route path={LINKS.platform_settings} element={<Navigate to={LINKS.setting_platform_access} />} />
            <Route
              path={LINKS.setting_platform_access}
              element={<ProtectedAdminRoute element={<PlatformAccess />} />}
            />
            <Route
              path={LINKS.settings_code_challenges}
              element={<ProtectedAdminRoute element={<SettingsCodeChallenges />} />}
            />
            <Route path={LINKS.setting_mission_events} element={<ProtectedAdminRoute element={<MissionEvents />} />} />
            <Route path={LINKS.setting_prompts} element={<ProtectedAdminRoute element={<Prompts />} />} />
          </Route>
          <Route path={LINKS.dashboard_home} element={<ProtectedAdminRoute element={<Dashboard />} />}>
            <Route path={LINKS.dashboard_home} element={<ProtectedAdminRoute element={<Overview />} />} />
            <Route
              path={LINKS.dashboard_activity_logs}
              element={<ProtectedAdminRoute element={<DashboardActivityLogs />} />}
            />
          </Route>
          <Route path={LINKS.dashboard(`:id`)} element={<ProtectedAdminRoute element={<DashboardTeamView />} />} />
          <Route
            path={LINKS.dashboard_activity_log(`:id`)}
            element={<ProtectedAdminRoute element={<DashboardTeamActivityLog />} />}
          />
          <Route path={LINKS.organizations} element={<ProtectedAdminRoute element={<Organizations />} />} />
          <Route
            path={LINKS.organization_profile(`:id`)}
            element={<ProtectedAdminRoute element={<OrganizationProfile />} byPassFields={[`isSignalClient`]} />}
          />
          <Route path={LINKS.lists} element={<ProtectedAdminRoute element={<Lists />} />} />
          <Route path={LINKS.list(`:slug`)} element={<ProtectedAdminRoute element={<List />} />} />
        </Route>
        <Route path="/termsheets" element={<TermSheets />} />
      </Route>
    </Route>
  </ReactRouterRoutes>
);

// Function to check if the user has any of the specified roles
const hasAnyField = (fields, requiredFields) =>
  requiredFields.some((field) => Object.keys(fields).includes(field) && fields[field]);

/**
 * ProtectedAdminRoute
 * Route wrapper to protect admin routes
 *
 * @param {Any}     element
 * @param {String}  navigateTo
 * @param {Array}   byPassFields   // Optional override field
 * @returns
 */
const ProtectedAdminRoute = ({ element, navigateTo, byPassFields }) => {
  const { isAdmin, ...rest } = useAuth();

  if (!isAdmin && (!byPassFields.length || !hasAnyField(rest, byPassFields))) {
    return <Navigate to={navigateTo || LINKS.home} />;
  }

  return element;
};

ProtectedAdminRoute.defaultProps = {
  byPassFields: [],
};

ProtectedAdminRoute.propTypes = {
  element: PropTypes.any.isRequired,
  navigateTo: PropTypes.string,
  byPassFields: PropTypes.array,
};

/**
 * Desktop Route
 *
 * @param {Any}     element
 * @returns
 */
const DesktopRoute = ({ element }) => {
  const { isMobile } = useViewport();

  if (isMobile) {
    return <MobileMessage />;
  } else {
    return element;
  }
};

DesktopRoute.propTypes = {
  element: PropTypes.any.isRequired,
};

export default Routes;
