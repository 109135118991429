import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import { BANNER_TYPE, LINKS, MISSION_ROLE_ELIGIBILITY, MISSION_ROLE_STATES } from "constants/index";
import { margins } from "style";
import { useMissionRoleApplicant, usePublicMissionRoleEligibility } from "hooks";
import { Banner } from "components/Banner";
import { MarketingButton } from "components/Buttons";
import { Link } from "components/Links";
import { MissionRolesPublicApplyModal } from "components/Missions/Roles/Public";

/**
 * MissionRolesPublicApplyButton
 * 
 * @param {object}  role
 * @param {boolean} loading
 */
const MissionRolesPublicApplyButton = ({ role, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showAlreadyApplied, setShowAlreadyApplied] = useState(false);
  const { addApplicant } = useMissionRoleApplicant({});
  const { data, handleWithdrawApplication } = usePublicMissionRoleEligibility({ missionRoleId: role?.id });

  const handleClick = () => {
    switch (data?.message) {
      case MISSION_ROLE_ELIGIBILITY.has_pending_application:
        handleWithdrawApplication(data.pendingApplication, data.pendingApplication.missionRole, role);
        break;
      case MISSION_ROLE_ELIGIBILITY.no_user:
        setShowModal(true);
        break;
      case MISSION_ROLE_ELIGIBILITY.already_applied:
        setShowAlreadyApplied(true);
        break;
      case MISSION_ROLE_ELIGIBILITY.redirect_to_role:
        navigate(data.redirectTo);
        break;
      case MISSION_ROLE_ELIGIBILITY.create_application:
        addApplicant(role?.id);
        break;
      default:
        window.location = `${LINKS.signup}?role=${role?.slug}`;
    };
  };

  const handleSignup = () => {
    setShowModal(false);
    navigate(`${LINKS.signup}?role=${role?.slug}`)
  }

  return (
    <>
      {role?.state === MISSION_ROLE_STATES.published || loading ? <div css={styles.button_container}>
        <Link onClick={handleClick}>
          <MarketingButton loading={loading}>
            <FormattedMessage id="Missions.Apply" />
          </MarketingButton>
        </Link>
      </div> : (
        <Banner type={BANNER_TYPE.warning} isClosable={false}>
          <FormattedMessage
            id="Missions.Roles.Public.MissionsRolesPublicHeader.BannerClosed"
            values={{
              link: <Link to={LINKS.signup} routerEnabled>here</Link>,
            }}
          />
        </Banner>
      )}
      {showAlreadyApplied && (
        <Banner type={BANNER_TYPE.warning} isClosable={false} css={styles.applied_banner}>
          <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHeader.AlreadyApplied" />  
        </Banner> 
      )}
      <MissionRolesPublicApplyModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSignup}
        role={role}
      />
    </>
  );
};

const styles = {
  button_container: css`
    margin-top:2rem;
  `,
  applied_banner: css`
    margin-top: ${margins.xxsmall};
  `
};

MissionRolesPublicApplyButton.propTypes = {
  role: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default MissionRolesPublicApplyButton;
