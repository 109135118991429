import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { useAuth, useTeam } from "hooks";
import { SideNavExpander, SideNavLink } from "components/Nav/SideNav";
import { ICON_TYPE } from "components/Icon";
import { LINKS } from "constants/index";

/**
 * MenuItemSquads
 *
 * @param {Object}   team
 * @param {Array}    subNavItems
 */
const MenuItemSquads = ({ team, subNavItems, ...props }) => {
  const { id } = useParams();
  const { isShowcase, isClient, isSignalClient, teams } = useAuth();
  const [expandedTeamSlug, setExpandedTeamSlug] = useState(
    isShowcase || isSignalClient ? teams[0].slug : isClient ? id : null
  );
  const { teamPermissions, permissionsLoading } = useTeam({ id: team?.slug });
  const navItems = subNavItems || DEFAULT_SQUAD_NAVIGATION(team?.slug);

  return isShowcase || isClient || isSignalClient ? (
    <SideNavExpander
      key={team?.slug}
      icon={ICON_TYPE.network}
      data={team}
      items={permissionsLoading ? [] : navItems?.filter((item) => teamPermissions?.[item.permissionKey])}
      gradientLabel={isShowcase && <FormattedMessage id="Nav.SideNav.Live" />}
      isExpanded={team?.slug === expandedTeamSlug}
      onToggle={() => {
        if (team?.slug === expandedTeamSlug) {
          setExpandedTeamSlug(null);
        } else {
          setExpandedTeamSlug(team?.slug);
        }
      }}
      {...props}
    />
  ) : (
    <SideNavLink key={team?.slug} type={ICON_TYPE.network} to={LINKS.squad_profile(team?.slug)} routerEnabled>
      {team?.name}
    </SideNavLink>
  );
};

const DEFAULT_SQUAD_NAVIGATION = (slug) => [
  {
    id: "profile",
    title: <FormattedMessage id="Nav.SideNav.Squads.Overview" />,
    link: LINKS.squad_profile(slug),
    icon: ICON_TYPE.analytics,
    permissionKey: "canAccessProfile",
  },
  {
    id: "insights",
    title: <FormattedMessage id="Nav.SideNav.Squads.Insights" />,
    link: LINKS.squad_insights(slug),
    icon: ICON_TYPE.analytic,
    permissionKey: "canAccessInsights",
  },
  {
    id: "engineering",
    title: <FormattedMessage id="Nav.SideNav.Squads.Engineering" />,
    link: LINKS.squad_engineering_code_telemetry(slug),
    icon: ICON_TYPE.status,
    permissionKey: "canAccessEngineering",
  },
  {
    id: "effort_report",
    title: <FormattedMessage id="Nav.SideNav.Squads.EffortReport" />,
    link: LINKS.squad_effort_report(slug),
    icon: ICON_TYPE.timerStart,
    permissionKey: "canAccessEffortReport",
  },
  {
    id: "daily_report",
    title: <FormattedMessage id="Nav.SideNav.Squads.DailyReport" />,
    link: LINKS.squad_daily_report(slug),
    icon: ICON_TYPE.file,
    permissionKey: "canAccessDailyReport",
  },
  {
    id: "settings",
    title: <FormattedMessage id="Nav.SideNav.Squads.Settings" />,
    link: LINKS.squad_signal_installation_settings(slug),
    icon: ICON_TYPE.gear,
    permissionKey: "canAccessSettings",
  },
];

MenuItemSquads.propTypes = {
  team: PropTypes.object.isRequired,
  subNavItems: PropTypes.array,
};

export default MenuItemSquads;
