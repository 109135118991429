import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors, margins,  } from "style";
import { H1, MarketingHeader } from "components/Text";
import CheckList from "components/CheckList";
import { TextLoader } from "components/Loader";
import Background from "assets/images/main-hero-bg.webp";
import { UserLocation } from "components/User";
import MissionRolesPublicApplyButton from "./MissionRolesPublicApplyButton";
import { convertYouTubeVideoUrl } from "utils";

/**
 * MissionRolesPublicHeader
 * 
 * @param {object}  role
 * @param {string}  id
 * @param {boolean} loading
 */
const MissionRolesPublicHeader = ({ id, role, loading }) => {
  return (
    <>
      <div css={styles.container}>
        <NarrowContainer>
          <Grid>       
            <Grid.col start={1} end={7}>
              <Col gap="0rem">
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHeader.Header" />
                </MarketingHeader>
                <H1 isPublic css={styles.title} loading={loading}>
                  {role?.name}
                </H1>
                <CheckList data={!loading ? [
                  ...formatList(role?.disciplines, 'Missions.Roles.Public.MissionsRolesPublicHeader.Disciplines'),
                  ...formatList(role?.primarySkills, 'Missions.Roles.Public.MissionsRolesPublicHeader.Skills'),
                  ...formatList(role?.secondarySkills, 'Missions.Roles.Public.MissionsRolesPublicHeader.SecondarySkills'),
                  ...formatList(role?.locations, 'Missions.Roles.Public.MissionsRolesPublicHeader.Region'),
                  role?.location ? <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHeader.Location" values={{ data: <UserLocation data={role} /> }} /> : null,
                  role?.engagementType ? <FormattedMessage id={`Missions.Roles.RoleDetails.EngagementType.${role?.engagementType}`} /> : null,
                  <FormattedMessage
                    id="Missions.Roles.Public.MissionsRolesPublicHeader.WorkArrangement"
                    values={{
                      data: <FormattedMessage id={`Missions.MissionRole.WorkArrangement.${role?.workArrangement}`} />
                    }}
                  />,
                  ].filter(Boolean) : [
                    <TextLoader width="20rem" />,
                    <TextLoader width="20rem" />,
                    <TextLoader width="20rem" />,
                    <TextLoader width="20rem" />,
                  ]} 
                />
                <MissionRolesPublicApplyButton role={{ ...role, id }} loading={loading} />
              </Col>
            </Grid.col>
            {role?.publicViewSettings?.videoLink && role?.publicViewSettings?.videoLinkEnabled && (
              <Grid.col start={7} end={13}>
                <div css={styles.iframe_container}>
                  <iframe 
                    css={styles.frame} 
                    src={convertYouTubeVideoUrl(role?.publicViewSettings?.videoLink)}
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                  />
                </div>
              </Grid.col>
            )}
          </Grid>
        </NarrowContainer>
      </div>
    </>
  );
};

const formatList = (data, messageId) => {
  if (!data || data.length === 0) {
    return []
  } else {
    return [
      <FormattedMessage 
        id={messageId}
        values={{ 
          data: data?.map(({ name }) => name)?.join(', ')
        }}
      /> 
    ]
  }
}

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 21rem 0 12rem 0;
    background: ${colors.white} url(${Background}) no-repeat center center;
    background-size: 50%;
    background-position: bottom right;
    margin-top:-9rem;
    z-index:2;
    
    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 0;
      background:#fff;
      margin-top:0;
    }
  `,
  title: css`
    margin-bottom:2rem; 
  `,
  button_container: css`
    margin-top:2rem;
  `,
  iframe_container: css`
    width:100%;
    padding-top: 75%;
    position: relative;
    overflow:hidden;
    border-radius:0.6rem;
  `,
  frame: css`
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left:0
    bottom:0;
    right:0;
  `,
  applied_banner: css`
    margin-top: ${margins.xxsmall};
  `
};

MissionRolesPublicHeader.propTypes = {
  role: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default MissionRolesPublicHeader;
