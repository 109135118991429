import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import DisplayBox from "components/DisplayBox";
import { GET_TIME_ZONES } from "graphql/queries";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE, DECORATORS } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { formatCurrency, formatDecimal } from "utils";
import { useAuth } from "hooks";
import { CURRENCIES, FEATURE_FLAGS, PAGES, PAYMENT_FREQUENCIES } from "constants/index";

/**
 * Profile Rate & Availability
 *
 * Depending on the user access level, we will show rate and capacity.
 * If the user is a partner (editRate === true), we hide all rate-related information
 * as that is controlled by admins.
 *
 * @params {Array}    data
 */
const ProfileRate = ({ data, ...props }) => {
  const { canViewEditRate, canViewEditFullTimeEmployment, isAdmin, featureFlags } = useAuth();

  const showRate = featureFlags?.some(
    ({ enabled, name }) => name === FEATURE_FLAGS.hideProfilesRate && enabled === false
  );

  const rateData = {
    rate: {
      frequency: PAYMENT_FREQUENCIES.hourly,
      amount: data?.rate?.amount || "",
      currency: data?.rate?.currency || "",
    },
  };

  const yearlyRateData = {
    yearlyRate: {
      yearlyAmount: data?.yearlyRate?.yearlyAmount || "",
      yearlyRateEnabled: data?.yearlyRate?.yearlyRateEnabled || false,
    },
  };

  const employmentData = {
    openToEmployment: data?.openToEmployment || false,
  };

  const capacityData = {
    capacity: data?.capacity || "",
    timezone: data?.timezone?.value || "",
  };

  const isEmpty =
    !data?.capacity &&
    !data?.rate?.amount &&
    !data?.yearlyRate?.yearlyAmount &&
    !data?.openToEmployment &&
    !data?.timezone;

  return (
    <DisplayBox
      title={
        <FormattedMessage
          id={canViewEditRate ? `Profile.ProfileRateAndAvailability.Title` : `Profile.ProfileAvailability.Title`}
        />
      }
      isEmpty={isEmpty}
      editButtonText={<FormattedMessage id="Profile.ProfileRateAndAvailability.AddRateAvailability" />}
      subtitle={
        isAdmin &&
        data?.rateLastUpdatedAt && (
          <FormattedMessage id="Global.LastUpdated" values={{ dateTime: moment(data?.rateLastUpdatedAt).fromNow() }} />
        )
      }
      numColumns={3}
      data={[
        ...(canViewEditRate && showRate ? RATE_DATA(data) : []),
        ...AVAILABILITY_DATA(data),
        ...(canViewEditFullTimeEmployment ? EMPLOYMENT_DATA(data) : []),
      ]}
      parentKeyName="id"
      parentKeyValue={data?.id}
      updateId={data?.id}
      initialValues={{
        ...capacityData,
        ...(canViewEditRate ? rateData : {}),
        ...(canViewEditFullTimeEmployment ? employmentData : []),
        ...(canViewEditRate ? yearlyRateData : {}),
      }}
      isEditable={true}
      {...props}
    />
  );
};

/**
 * Capacity/Availability fields
 */
const AVAILABILITY_DATA = (data) => [
  {
    title: <FormattedMessage id="Profile.ProfileRateAndAvailability.LabelAvailability" />,
    body:
      parseInt(data?.capacity) > 0 ? (
        <FormattedMessage
          id="Profile.ProfileRateAndAvailability.DataAvailability"
          values={{ capacity: data?.capacity }}
        />
      ) : (
        <FormattedMessage id="Profile.ProfileRateAndAvailability.NotAvailable" values={{ capacity: data?.capacity }} />
      ),
  },
  {
    title: <FormattedMessage id="Profile.ProfileRateAndAvailability.LabelTimezone" />,
    body: data?.timezone?.name,
  },
];

/**
 * FORM - Rate fields
 */
const FORM_EMPLOYMENT_DATA = [
  {
    type: TYPE.checkbox,
    size: SIZE.large,
    properties: {
      type: "checkbox",
      name: "openToEmployment",
      label: "I'm open to full time employment",
      description: "Enable if you're open to starting with a contract and later exploring a full-time option.",
    },
  },
];

/**
 * Rate fields
 */
const RATE_DATA = (data) => [
  {
    title: <FormattedMessage id="Profile.ProfileRateAndAvailability.LabelRate" />,
    body: (
      <FormattedMessage
        id={data?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
        values={{
          amount: formatCurrency(data?.rate?.amount, data?.rate?.currency),
          type: <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${data?.rate?.frequency}`} />,
        }}
      />
    ),
  },
  {
    title: <FormattedMessage id="Profile.ProfileRateAndAvailability.YearlyRate" />,
    body: (
      <FormattedMessage
        id={
          data?.yearlyRate?.yearlyAmount
            ? formatCurrency(data?.yearlyRate?.yearlyAmount, data?.rate?.currency)
            : "Global.NotProvided"
        }
      />
    ),
  },
];

const EMPLOYMENT_DATA = (data) => [
  {
    title: <FormattedMessage id="Profile.ProfileRateAndAvailability.EmploymentType" />,
    body: <FormattedMessage id={`Profile.ProfileEmployment.${data?.openToEmployment}`} />,
  },
];

/**
 * FORM - Rate fields
 */
const FORM_RATE_DATA = [
  {
    label: "Preferred Rate",
    type: TYPE.h4,
    size: SIZE.large,
  },
  {
    label: "Hourly Rate",
    type: TYPE.input,
    size: SIZE.small,
    parentObjectKey: "rate",
    relatedValueFunc: [
      {
        parentObjectKey: "yearlyRate",
        name: "yearlyAmount",
        valueFunc: (value) => formatDecimal(value * 52 * 40 * 0.7),
        state: false,
        relatedState: "yearlyRateEnabled",
        relatedStateParentkey: "yearlyRate",
        relatedStateReason: "disabled",
      },
    ],
    valueFunc: (val) => formatDecimal(val),
    properties: {
      name: "amount",
      type: "number",
      required: true,
      min: 1,
      step: 0.01,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.dollarBill,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    label: "Currency",
    type: TYPE.select,
    size: SIZE.small,
    parentObjectKey: "rate",
    properties: {
      name: "currency",
      required: true,
      options: CURRENCIES,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.coin,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    label: "Yearly Salary",
    type: TYPE.input,
    size: SIZE.small,
    parentObjectKey: "yearlyRate",
    disabledCondition: {
      field: "yearlyRateEnabled",
      value: false,
      fieldParentKey: "yearlyRate",
    },
    properties: {
      name: "yearlyAmount",
      type: "number",
      required: true,
      step: 0.01,
    },
    valueFunc: (val) => formatDecimal(val),
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.dollarBill,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    type: TYPE.checkbox,
    size: SIZE.large,
    parentObjectKey: "yearlyRate",
    relatedValueFunc: [
      {
        parentObjectKey: "yearlyRate",
        name: "yearlyAmount",
        valueFunc: (value) => formatDecimal(value * 52 * 40 * 0.7),
        relatedState: "amount",
        relatedStateParentkey: "rate",
        relatedStateReason: "valueChange",
      },
    ],
    properties: {
      name: "yearlyRateEnabled",
      label: "Customize yearly salary",
    },
  },
];

/**
 * FORM - Availability/Capacity fields
 */
const FORM_CAPACITY_LABEL = [
  {
    label: "Capacity",
    type: TYPE.h4,
    size: SIZE.large,
  },
];

const FORM_CAPACITY_DATA = [
  {
    label: "Hours/week",
    type: TYPE.input,
    size: SIZE.small,
    valueFunc: (val) => formatDecimal(val),
    properties: {
      type: "number",
      name: "capacity",
      required: true,
      placeholder: "Hours/week",
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.hourglass,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    label: "Time Zone",
    type: TYPE.select,
    size: SIZE.small,
    properties: {
      name: "timezone",
      queryName: GET_TIME_ZONES,
      queryKeyName: "timezones",
      labelKey: "name",
      required: true,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.pin,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const FORM_DATA = (editRate, editEmployment) => {
  return [
    {
      style: css`
        width: 100%;
      `,
      items: [
        ...(editRate ? FORM_RATE_DATA : []),
        ...(editRate ? FORM_CAPACITY_LABEL : []),
        ...FORM_CAPACITY_DATA,
        ...(editEmployment ? FORM_EMPLOYMENT_DATA : []),
      ],
    },
  ];
};

const FORM_SETTINGS = (editRate, editEmployment) => {
  return {
    size: MODAL_SIZE.medium,
    data: FORM_DATA(editRate, editEmployment),
    title: (
      <FormattedMessage
        id={editRate ? `Profile.ProfileRateAndAvailability.FormTitle` : `Profile.ProfileAvailability.FormTitle`}
      />
    ),
    description: (
      <FormattedMessage
        id={
          editRate
            ? `Profile.ProfileRateAndAvailability.FormDescription`
            : `Profile.ProfileAvailability.FormDescription`
        }
      />
    ),
    numColumns: 3,
    mutationData: {
      mutationName: {
        add: UPDATE_PROFILE,
        update: UPDATE_PROFILE,
      },
    },
    page: PAGES.rateCapacity,
  };
};

ProfileRate.propTypes = {
  data: PropTypes.object,
};

const ProfileAvailability = withModalForm(ProfileRate, FORM_SETTINGS(false, false));

const ProfileAvailabilityAndEmployment = withModalForm(ProfileRate, FORM_SETTINGS(false, true));

const ProfileRateAvailabilityAndEmployment = withModalForm(ProfileRate, FORM_SETTINGS(true, true));

export { ProfileAvailability, ProfileAvailabilityAndEmployment, ProfileRateAvailabilityAndEmployment };

export default withModalForm(ProfileRate, FORM_SETTINGS(true, false));
