import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { useProfile } from "hooks";
import PageWrapper from "components/Containers/NarrowContainer";
import { ProfileNav } from "components/Profile";
import { Grid } from "components/Containers";
import { ApplicationPreviousAssignments } from "components/Missions/Applicants";
import Card from "components/Card";
import { Spinner } from "components/Loader";
import PageTitle from "components/PageTitle";

/**
 * ProfileAssignments
 */
const ProfileAssignments = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { data, loading } = useProfile({ profileId: id });

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.Assignments.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Profile.Assignments.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            {loading ? (
              <Spinner size="1.5rem" />
            ) : (
              <Card>
                <ApplicationPreviousAssignments profileId={data?.profile?.id} />
              </Card>
            )}
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ProfileAssignments;
