import { ApolloClient, InMemoryCache } from "@apollo/client"; // HttpLink remove
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { profileCompletionVar } from "graphql/variables";
import { EXTERNAL_LINK_VALUES } from "./externalLinkTypes";
import { QUESTION_TYPES } from "./assessmentConfigs";

/* eslint-disable no-undef */
const CSRF_TOKEN = document?.querySelector("meta[name=csrf-token]")?.getAttribute("content");

const CACHE = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        timeSheets: relayStylePagination(),
        partners: relayStylePagination(),
        profileCompletion: {
          read() {
            return profileCompletionVar();
          },
        },
      },
    },
  },
});

const CLIENT = new ApolloClient({
  link: new createUploadLink({
    // Replaced HttpLink with createUploadLink to allow for file uploads
    credentials: "include",
    headers: {
      "X-CSRF-Token": CSRF_TOKEN,
      "access-token": localStorage.getItem("token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid"),
    },
  }),
  cache: CACHE,
  connectToDevTools: true, // TODO: Add an environment variable to put this to false for production
});

const LOCALE = "en";
const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY;
const AM5_LICENSE_KEY = process.env.AM5_LICENSE_KEY;
const LINKED_IN_PREFIX = `https://www.linkedin.com/in/`;
const THIS_WEEK = "this_week";
const LAST_WEEK = "last_week";
const CLOUDFRONT_URL = "https://d3e4xd8b7ld2vz.cloudfront.net/";
const CODE_CHALLENGE_PASS_THRESHOLD = 60;
const MISSIONS_RESULTS_PER_PAGE = 20;
const SQUAD_INSIGHTS_PER_PAGE = 20;
const CLIENT_MEETING_URL = "https://meetings.hubspot.com/ashley224?embed=true";
const ORGANIZATIONS_RESULTS_PER_PAGE = 20;
const MISSION_ROLE_APPLICANTS_PER_PAGE = 100;
const USER_ACCESS_RESULTS_PER_PAGE = 40;
const CODE_CHALLENGES_RESULTS_PER_PAGE = 50;
const TIME_CARD_RESULTS_PER_PAGE = 50;

export { default as TIME_PERIODS } from "./timePeriods";
export { default as COUNTRIES } from "./countries";
export { default as NO_OF_SOFTWARE_ENGINEERS } from "./noOfSoftwareEngineers";
export { default as NO_OF_EMPLOYEES } from "./noOfEmployees";
export { default as CURRENCIES } from "./currencies";
export { CURRENCY_NAMES } from "./currencies";
export { default as CHALLENGES } from "./challenges";
export { default as EXTERNAL_LINK_TYPES } from "./externalLinkTypes";
export { default as LINKS } from "./links";
export { default as PAYMENT_FREQUENCIES } from "./paymentFrequencies";
export { default as PROFILE_PREFERENCES } from "./profilePreferences";
export { default as BANNER_TYPE } from "./bannerTypes";
export { default as CAPACITY_STATUS } from "./capacityStatus";
export { default as PARTNER_CHANNELS } from "./partnerChannels";
export { default as DISCIPLINES } from "./disciplines";
export { default as ARCHETYPES_SYMBOLS } from "./archetypeSymbols";
export { default as ROLES } from "./roles";
export { default as MEMBER_STATUS } from "./memberStatus";
export { default as REJECTED_REASON } from "./rejectedReason";
export { default as APPLICANT_TYPES } from "./applicantTypes";
export { default as AVAILABILITIES } from "./availabilities";
export { default as YEARS_OF_EXPERIENCE } from "./yearsOfExperience";
export { default as CLOUDFRONT } from "./availabilities";
export { default as TIME_CARD_STATUS, TIME_CARD_OPTIONS } from "./timeCardStatus";
export { default as DATE_FORMAT_TYPE } from "./dateFormatType";
export { default as TIMELINE_EVENTS } from "./timelineEvents";
export { default as ACTIVITY_TYPES } from "./activityTypes";
export { default as ARCHIVE_REASON } from "./archiveReason";
export { default as TEAM_CATEGORY_SCORES } from "./teamCategoryScores";
export { default as FEATURE_FLAGS } from "./featureFlags";
export { default as REQUIREMENT_TYPES } from "./requirementTypes";
export { default as CHALLENGES_TYPES, DEFAULT_CHALLENGE } from "./challengesTypes";
export { default as CHEATING_TYPES } from "./cheatingTypes";
export { default as TERM_SHEET_TYPES } from "./termSheetTypes";
export { default as SETTINGS_SIDENAV } from "./settings";
export { default as CODEHOSTINGSERVICES } from "./codeHostingServices";
export { default as COMMENTABLE_TYPES } from "./commentableTypes";
export { default as DIRECTION } from "./direction";
export { default as CODE_CHALLENGE_TYPES } from "./codeChallengeTypes";
export { default as TRACKING_PAGE_VIEWS } from "./trackingPageViews";
export { default as PAGES } from "./pages";
export { default as TRACKING_SECTIONS } from "./trackingSections";
export { default as ERROR_CODES } from "./errorCodes";
export { default as ARCHETYPES } from "./archetypes";
export { default as RESOURCE_TYPES } from "./resourceTypes";
export { default as CODE_CHALLENGE_STATUS } from "./codeChallengeStatus";
export { default as DEEL_TASK_STATUS } from "./deelTaskStatus";
export { default as ORIGINS } from "./origins";
export { default as TEAM_ACTIVITY_TYPES } from "./teamActivityTypes";
export { default as ORGANIZATION_SIZE_OPTIONS } from "./organizationSizeOptions";
export { default as PARTNER_TYPES } from "./partnerTypes";
export { default as LIST_TYPES, LIST_TYPES_HUMANIZED } from "./listTypes";
export { default as USER_FORM_CONFIGS } from "./userFormConfigs";
export { default as ENGINEERING_LEVELS } from "./engineeringLevels";
export { default as SQUAD_STATUS } from "./squadStatus";
export { default as WORK_ARRANGEMENT_OPTIONS, WORK_ARRANGEMENTS } from "./workArrangements";
export { default as ENGAGEMENT_TYPES_OPTIONS, ENGAGEMENT_TYPES } from "./engagementTypes";
export {
  default as GLOBAL_FORM_FILTERS,
  GLOBAL_SEARCH,
  GLOBAL_VALUE_TYPES,
  STATIC_FILTERS,
  OPERATORS,
  ALLOWED_FILTERS_BY_USER_TYPE,
} from "./globalFormFilters";
export { default as TERMSHEET_EVENTS } from "./termSheetEvents";
export { default as USER_TYPES, USER_TRANSITIONS } from "./userTypes";
export { default as USER_ACTIVATION_STATUSES } from "./userActivationStatus";
export { default as READ_ONLY_CHANNELS } from "./readOnlyChannels";
export { default as CHALLENGE_CATEGORIES } from "./challengeCategories";
export { default as BILLING_ACCOUNT_INTERVALS } from "./billingAccountIntervals";
export { default as INTERVIEW_STATUSES } from "./interviewStatuses";
export { default as TERM_SHEET_SCENARIOS } from "./termSheetScenarios";
export { default as CHANGE_KEYS } from "./termSheetScenarioChangeKeys";
export { default as SENTIMENTS } from "./sentimentTypes";
export { default as INSIGHT_TYPES, INSIGHT_TYPES_OPTIONS } from "./insightTypes";
export { default as INSIGHT_METRICS_OPTIONS } from "./insightMetrics";
export { default as FEEDBACK_OPTIONS } from "./feedbackOptions";
export { default as SQUAD_METRIC_TYPES } from "./squadMetricTypes";
export { default as TAB_OPTIONS } from "./tabOptions";
export { default as RATE_FREQUENCIES } from "./rateFrequencies";
export { default as PROFILE_STATUS_MAPPINGS } from "./profileStatusMappings";
export { default as SORT, APPLICANT_LIST_SORTING_OPTIONS } from "./sorts";
export { default as CONNECTION_STATES } from "./connectionStates";
export { default as TEAM_TYPES_OPTIONS, TEAM_TYPES } from "./teamTypes";
export { default as VETTING_STEPS_OPTIONS, VETTING_STEPS } from "./vettingSteps";
export { default as DAYS_OF_WEEK } from "./daysOfWeek";
export { default as ASSESSMENT_CONFIGS } from "./assessmentConfigs";
export { default as APPLICANT_LIST_COLUMNS } from "./applicantListColumns";
export { default as MISSION_ROLE_STATES } from "./missionRoleStates";
export { default as APPLICATION_STEPS } from "./applicationSteps";
export { default as MISSION_ROLE_ELIGIBILITY } from "./missionRoleEligibility";

export {
  CLIENT,
  CACHE,
  LOCALE,
  CLOUDFRONT_URL,
  CSRF_TOKEN,
  GOOGLE_API_KEY,
  AM5_LICENSE_KEY,
  LINKED_IN_PREFIX,
  THIS_WEEK,
  LAST_WEEK,
  CODE_CHALLENGE_PASS_THRESHOLD,
  MISSIONS_RESULTS_PER_PAGE,
  SQUAD_INSIGHTS_PER_PAGE,
  CLIENT_MEETING_URL,
  ORGANIZATIONS_RESULTS_PER_PAGE,
  MISSION_ROLE_APPLICANTS_PER_PAGE,
  USER_ACCESS_RESULTS_PER_PAGE,
  CODE_CHALLENGES_RESULTS_PER_PAGE,
  TIME_CARD_RESULTS_PER_PAGE,
  EXTERNAL_LINK_VALUES,
  QUESTION_TYPES,
};
