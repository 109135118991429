import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { LINKS } from "constants/index";
import { ApplicationForm } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import { PageWrapper } from "components/Containers";
import { useAuth, useMissionRoleApplicant } from "hooks";
import { PrimaryLink } from "components/Links";

/**
 * MissionApplications
 */
const MissionApplications = () => {
  const intl = useIntl();
  const { id } = useParams();
  const { isFreelancer } = useAuth();
  const { data, loading } = useMissionRoleApplicant({ id });

  const [rateEditing, setRateEditing] = useState(false);
  const [questionnaireEditing, setQuestionnaireEditing] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(data);
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applications.Title" })}</title>
      </Helmet>
      <PageWrapper>
        {selected && (
          <PageTitle
            title={
              <FormattedMessage id={"MissionApplications.PageTitle"} values={{ role: selected?.missionRole?.name }} />
            }
            backLink={
              isFreelancer && (
                <PrimaryLink to={LINKS.mission_applications} routerEnabled>
                  <FormattedMessage id="MissionApplicatons.PageTitle.BackLink.Text" />
                </PrimaryLink>
              )
            }
          />
        )}
        <ApplicationForm
          selected={selected}
          loading={loading}
          rateEditing={rateEditing}
          setRateEditing={setRateEditing}
          questionnaireEditing={questionnaireEditing}
          setQuestionnaireEditing={setQuestionnaireEditing}
        />
      </PageWrapper>
    </>
  );
};

export default MissionApplications;
