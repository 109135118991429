import React from "react";
import { Helmet } from "react-helmet";
import { useOutletContext } from "react-router";
import { useIntl } from "react-intl";
import { Grid } from "components/Containers";
import Card from "components/Card";
import {
  CandidateProfileStatus,
  CandidateTitle,
  CandidateCodeChallenge,
  CandidateInterview,
} from "components/Candidate";
import { ApplicationForm, ApplicantAbandon } from "components/Missions/Applicants";
import { APPLICANT_TYPES } from "constants/index";

/**
 * Home
 */
const Home = () => {
  const intl = useIntl();
  const { application, loadingApplication, profileId, hasCodeChallengeRequirement, ...rest } = useOutletContext();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Candidate.Home.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <CandidateTitle
            title={`Candidate.Home.Title${application ? "Application" : ""}`}
            description={application ? `Candidate.Role.Body` : `Candidate.Home.Body`}
            profileId={profileId}
            values={{
              role: application?.missionRole?.name,
            }}
          />
        </Grid.col>
        {application?.state === APPLICANT_TYPES.pending && (
          <Grid.col start={1} end={13}>
            <ApplicantAbandon application={application} />
          </Grid.col>
        )}
        {application && (
          <>
            <Grid.col start={1} end={13}>
              <ApplicationForm
                selected={application}
                loading={loadingApplication}
                hideProgress
                hideRequirements
                hideDetails
                {...rest}
              />
            </Grid.col>
          </>
        )}
        {!hasCodeChallengeRequirement && (
          <Grid.col start={1} end={13}>
            <Card>
              <CandidateCodeChallenge profileId={profileId} />
            </Card>
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <Card>
            <CandidateInterview profileId={profileId} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <CandidateProfileStatus />
          </Card>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Home;
