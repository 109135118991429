import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Outlet, useParams } from "react-router-dom";
import { useAuth, useProfile, useTeam, useTracking } from "hooks";
import { LINKS, ARCHETYPES, TRACKING_PAGE_VIEWS, PAGES } from "constants/index";
import PageTitle, { LAYOUT } from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { NavList } from "components/Nav";
import { Banner, BannerShowcaseSignup } from "components/Banner";
import EditInPlace from "components/EditInPlace";

/**
 * Squads
 */
const Squads = () => {
  const { profileId, isAdmin, isMember, isShowcase } = useAuth();
  const [navItems, setNavItems] = useState([]);
  const { id } = useParams();
  const {
    loading,
    data: selectedSquad,
    error,
    teamPermissions,
    permissionsLoading,
    updateTeam,
    updating,
  } = useTeam({ id });
  const { data: ProfileData } = useProfile({ profileId });
  const SLTL = ProfileData?.profile?.archetype?.toUpperCase();
  const isSLTL = SLTL === ARCHETYPES.squad_lead || SLTL === ARCHETYPES.tech_lead;
  const { trackPage } = useTracking();

  const handleTitleSubmit = (name) => {
    updateTeam(id, { name });
  };

  useEffect(() => {
    setNavItems(
      permissionsLoading ? [] : pages(teamPermissions, id, selectedSquad, isAdmin).filter((page) => page.isPermissible)
    );
  }, [teamPermissions, id, selectedSquad, isAdmin, permissionsLoading]);

  useEffect(() => {
    if (isShowcase) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.squadDetail].page_name, TRACKING_PAGE_VIEWS[PAGES.squadDetail]);
    }
  }, []);

  return (
    <>
      {(loading || selectedSquad) && (
        <>
          <PageTitle
            backLink={
              isAdmin && (
                <PrimaryLink to={LINKS.squads} routerEnabled>
                  <FormattedMessage id="Squads.Profile.BackLink" />
                </PrimaryLink>
              )
            }
            loading={loading}
            title={
              <EditInPlace onSubmit={handleTitleSubmit} isEditable={isAdmin && !loading} updating={updating}>
                {selectedSquad?.name}
              </EditInPlace>
            }
            subtitle={
              !isMember && (
                <PrimaryLink to={LINKS.organization_profile(selectedSquad?.organization?.slug)} routerEnabled>
                  <FormattedMessage
                    id="Missions.MissionOrganizationName.Name"
                    values={{ name: selectedSquad?.organization?.name, icon: "" }}
                  />
                </PrimaryLink>
              )
            }
            layout={navItems.length > 5 ? LAYOUT.vertical : LAYOUT.horizontal}
            actionBar={<NavList pages={navItems} />}
            asHeader
          >
            <BannerShowcaseSignup
              css={css`
                padding: 4rem 0 0 0;
              `}
            />
            {isShowcase && (
              <Banner keyName="showSquadBanner">
                <FormattedMessage
                  id="Squads.Profile.Banner.Title1"
                  values={{
                    intro: (
                      <b>
                        <FormattedMessage id="Squads.Profile.Banner.Title2" />
                      </b>
                    ),
                  }}
                />
              </Banner>
            )}
          </PageTitle>
        </>
      )}
      <Outlet
        context={{
          selectedSquad,
          loading,
          error,
          id,
          isSLTL: isAdmin ? false : isSLTL,
          teamPermissions,
        }}
      />
    </>
  );
};

const defaultPageProps = {
  routerEnabled: true,
  end: false,
  isActive: true,
};

const pages = (teamPermissions, id, selectedSquad, isAdmin) => [
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Profile" />,
    to: LINKS.squad_profile(id),
    isPermissible: teamPermissions?.canAccessProfile,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Insights" />,
    to: LINKS.squad_insights(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessInsights,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Termsheet" />,
    to: LINKS.squad_term_sheet(id),
    // TODO: Fix the permissions from the backend like teamPermissions?.canAccessTermSheet
    isPermissible: selectedSquad && teamPermissions?.canAccessTermSheet,
    isAdmin,
    props: {
      ...defaultPageProps,
      routerEnabled: false,
      target: "_blank",
    },
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Engineering" />,
    to: LINKS.squad_engineering_code_telemetry(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessEngineering,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.EffortReport" />,
    to: LINKS.squad_effort_report(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessEffortReport,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.DailyReport" />,
    to: LINKS.squad_daily_report(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessDailyReport,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Pulse" />,
    to: LINKS.squad_pulse(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessPulse,
    isAdmin,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Feedback" />,
    to: LINKS.admin_squads_feedback(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessClientFeedback,
    isAdmin,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Squads.Squad.SubNav.Settings" />,
    to: LINKS.squad_settings(id),
    isActive: true,
    isPermissible: teamPermissions?.canAccessSettings,
    props: defaultPageProps,
  },
];

export default Squads;
