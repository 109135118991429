import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { MUTATION_TYPE, useToast } from "hooks";
import { ADD_ORGANIZATION } from "graphql/mutations";
import { LINKS } from "constants/index";
import { GET_ORGANIZATIONS } from "graphql/queries";

/**
 * Organization Form
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 */
const OrganizationForm = ({ show, onClose, ...props }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const formProps = {
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_ORGANIZATION,
      },
      refetchQueries: [GET_ORGANIZATIONS],
    },
  };

  const handleComplete = (data) => {
    let id = data?.addOrganization?.organization?.id;
    onClose();
    toast.success(<FormattedMessage id={"Organizations.OrganizationForm.CreateSuccess"} />);
    navigate(LINKS.organization_profile(id));
  };

  return (
    <ModalForm
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      title={<FormattedMessage id={"Organizations.OrganizationForm.Title"} />}
      onCompleted={handleComplete}
      {...formProps}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
        },
      },
    ],
  },
];

OrganizationForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default OrganizationForm;
