import React from "react";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { breakpoints } from "style";
import { useAuth } from "hooks";

/**
 * ProfileId
 *
 * @params {Object}   data
 */
const ProfileId = ({ data }) => {
  const { isAdmin } = useAuth();

  if (!isAdmin || !data || !data.id) return null;

  return (
    <p css={styles.container}>
      <FormattedMessage id="Profile.ProfileBio.MemberID" values={{ id: data.id }} />
    </p>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.small};
    line-height: 1;
    color: ${colors.grayAnatomyLight1};
    text-align: center;
    margin-top: 1rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: 2rem;
    }
  `,
};

ProfileId.propTypes = {
  data: PropTypes.object,
};

export default ProfileId;
