import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Modal, { MODAL_SIZE } from "components/Modal";
import { Grid, Col } from "components/Containers";
import { fontSize } from "style";
import { BodyContent } from "components/Missions/Roles/Public/MissionRolesPublicHowItWorks";
import { H1 } from "components/Text";

/**
 * MissionRolesPublicApplyModal
 *
 * @param {Boolean}  show
 * @param {Function} onClose
 * @param {Object}   data
 * @param {Function} onSubmit
 */
const MissionRolesPublicApplyModal = ({ show, onClose, onSubmit, role }) => (
  <Modal
    show={show}
    onClose={onClose}
    showSave={true}
    showCancel={true}
    canSubmit={true}
    onSubmit={onSubmit}
    primaryButtonMessageId="Missions.Roles.Public.MissionsRolesPublicApplyModal.Cta"
    secondaryButtonMessageId="Global.Cancel"
    size={MODAL_SIZE.large}
    title={`Applying for ${role?.name}`}
  > 
    <Grid gap={`5rem`} rowGap={`5rem`} css={styles.container}>
      <Grid.col start={1} end={13} alignCenter>
        <Col css={styles.title_container}>
          <H1>
            <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHowItWorks.Title" />
          </H1>
          <span>
            <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicApplyModal.Subtitle" />            
          </span>
        </Col>
      </Grid.col>
      <BodyContent />
    </Grid>
  </Modal>
);

MissionRolesPublicApplyModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  role: PropTypes.object,
  onSubmit: PropTypes.func
};

const styles = {
  container: css`
    font-size: ${fontSize.medium};
    line-height: normal;
  `,
  title_container: css`
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  `
};

export default MissionRolesPublicApplyModal;
