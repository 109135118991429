import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Col, Grid } from "components/Containers";
import Card from "components/Card";
import { fontSize, colors, breakpoints } from "style";
import { WhiteLogo } from "components/Logo";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";
import Progress from "components/Progress";

/**
 * Login
 *
 * @param {Any}       children
 * @param {Object}    banner
 * @param {Boolean}   isRoleApplication
 * @param {Boolean}   signupCompleted
 */
const LoginContainer = ({ children, banner, isRoleApplication, signupCompleted }) => {
  const isVideo = banner.type === "video";
  const isImage = banner.type === "image";
  const isVideoOrImage = isVideo || isImage;

  return (
    <div css={styles.container}>
      <Grid cols={2} css={styles.grid_container} gap={0} rowGap={0}>
        <Grid.col css={styles.login}>
          <div css={styles.form_container}>{children}</div>
          <div css={styles.footer}>
            <FormattedMessage
              id="Signup.SignupFooter"
              values={{
                privacy: (
                  <PrimaryLink href={LINKS.privacy} target="_blank">
                    <FormattedMessage id="Signup.FormEmail.PrivacyLink" />
                  </PrimaryLink>
                ),
                terms: (
                  <PrimaryLink href={LINKS.terms_and_conditions} target="_blank">
                    <FormattedMessage id="Signup.FormEmail.TermsAndConditionsLink" />
                  </PrimaryLink>
                ),
              }}
            />
          </div>
        </Grid.col>
        <Grid.col css={styles.side_branding}>
          <Col css={styles.branded(isVideoOrImage)} style={{ "--cover-shot": banner.cover }}>
            <WhiteLogo css={styles.logo_lettering} fill={isVideoOrImage ? colors.white : colors.black} />
            {!isRoleApplication && (
              <>
                <span css={styles.title(isVideoOrImage)}>{banner.title}</span>
                {isVideo && (
                  <Video autoPlay loop muted playsInline>
                    <source src={banner.cover} type="video/mp4" />
                  </Video>
                )}
                {isImage && (
                  <div css={styles.image_container}>
                    <img src={banner.cover} />
                  </div>
                )}
                <Col css={styles.body} gap="0.5rem">
                  <span>
                    <b>{banner.label}</b>
                  </span>
                  <span>{banner.description}</span>
                </Col>
              </>
            )}
            {isRoleApplication && (
              <div css={styles.checklist}>
                <Card>
                  <Progress
                    title="Profile.CandidateProgress.Title"
                    steps={[
                      {
                        isComplete: signupCompleted,
                        label: "Missions.Applicants.ApplicantProgress.Step.signup",
                      },
                      {
                        isComplete: false,
                        label: "Missions.Applicants.ApplicantProgress.Step.profile_completion",
                      },
                      {
                        isComplete: false,
                        label: "Missions.Applicants.ApplicantProgress.Step.video_interview",
                      },
                      {
                        isComplete: false,
                        label: "Missions.Applicants.ApplicantProgress.Step.code_challenge",
                      },
                      {
                        isComplete: false,
                        label: "Missions.Applicants.ApplicantProgress.Step.role_application",
                      },
                    ]}
                  />
                </Card>
              </div>
            )}
          </Col>
        </Grid.col>
      </Grid>
    </div>
  );
};

const Video = styled.video`
  height: 65%;
  width: 64rem;
  object-fit: cover;
`;

const styles = {
  logo_lettering: css`
    width: 14rem;
    height: 2rem;
  `,
  responsive_logo: css`
    display: none;
    @media (max-width: ${breakpoints.portrait}) {
      display: block;
      margin-right: auto;
      margin-bottom: 7rem;
    }
  `,
  grid_container: css`
    min-height: 100vh;
    width: 100vw;
    align-items: flex-start;
  `,
  side_branding: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
  `,
  branded: (isVideo) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50vw;
    height: 100%;
    position: fixed;
    padding: ${isVideo ? "8rem 0rem 0rem 6rem" : "8rem 12rem 5rem 6rem"};
    background: ${isVideo ? colors.purpleRainDark6 : "var(--cover-shot) 50% 50% no-repeat"};
    background-size: cover;
    background-position: bottom left;

    @media (max-width: ${breakpoints.tablet}) {
      padding-right: 5rem;
    }

    @media (max-width: ${breakpoints.portrait}) {
      display: none;
    }
  `,
  footer: css`
    color: ${colors.grayAnatomyLight1};
    line-height: normal;
    margin-bottom: 5rem;
    margin-left: 5rem;
    margin-right: auto;
    margin-top: auto;
    padding-top: 3rem;

    @media (max-width: ${breakpoints.portrait}) {
      margin: auto 0 5rem 0;
    }
  `,
  login: css`
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  `,
  form_container: css`
    width: 38rem;
    max-width: 100%;
    margin: auto auto 0 auto;
    padding: 3rem 0;
  `,
  container: css`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: #fff;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${breakpoints.portrait}) {
      padding: 0 3rem;
    }
  `,
  image_container: css`
    height: 65%;
    width: 64rem;
    overflow: hidden;
  `,
  title: (isVideo) => css`
    font-size: ${isVideo ? fontSize.xlarge : fontSize.xxxlarge};
    font-weight: 600;
    color: #fff;
    margin-bottom: 2rem;
    line-height: normal;
    margin-top: auto;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.xlarge};
    }
  `,
  body: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.large};
  `,
  checklist: css`
    margin-top: auto;
    margin-bottom: auto;

    > * {
      margin-top: -14rem;
    }
  `,
};

LoginContainer.propTypes = {
  children: PropTypes.any,
  banner: PropTypes.object,
  isRoleApplication: PropTypes.bool,
  signupCompleted: PropTypes.bool,
};

export default LoginContainer;
