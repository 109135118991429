import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { APPLICATION_STEPS, LINKS } from "constants/index";
import { useAuth, useMissionRoleApplicant } from "hooks";
import { Grid, NarrowContainer } from "components/Containers";
import Header from "components/Header";
import Impersonating from "components/Impersonating";
import { GlobalApplicantProgress } from "components/Missions/Applicants";
import { CandidateProgress, CandidateRoleDetails, CandidateStatus } from "components/Candidate";
import Card from "components/Card";
import { Spinner } from "react-bootstrap";
import { PublicFooter } from "components/Public";
import { ProfileProgress } from "components/Profile";

/**
 * Home
 */
const Home = () => {
  const location = useLocation();
  const { profileId, originatedMissionRoleApplicant: isRoleApplicant } = useAuth();
  const { data: application, loading: loadingApplication } = useMissionRoleApplicant({ id: isRoleApplicant?.id });

  const [rateEditing, setRateEditing] = useState(false);
  const [questionnaireEditing, setQuestionnaireEditing] = useState(false);

  const isProfilePage = location.pathname === LINKS.candidate_profile;
  const PAGES = [...(isRoleApplicant ? ROLE_APPLICATION_PAGE : HOME_PAGE), ...PROFILE_PAGE];
  const hasCodeChallengeRequirement = application?.applicationChecklist?.some(
    (s) => s.name === APPLICATION_STEPS.code_challenge
  );

  return (
    <>
      <Header pages={PAGES} />
      <NarrowContainer>
        <Grid topMargin bottomMargin>
          <Grid.col start={1} end={9}>
            {loadingApplication && <Spinner />}
            {!loadingApplication && (
              <Outlet
                context={{
                  application,
                  loadingApplication,
                  profileId,
                  hasCodeChallengeRequirement,
                  rateEditing,
                  setRateEditing,
                  questionnaireEditing,
                  setQuestionnaireEditing,
                }}
              />
            )}
          </Grid.col>
          <Grid.col start={9} end={13}>
            <Grid cols={1}>
              <Grid.col start={1} end={1}>
                <Card>
                  {application ? (
                    <GlobalApplicantProgress
                      path={location.pathname}
                      missionRoleApplicant={application}
                      rateEditing={rateEditing}
                      setRateEditing={setRateEditing}
                      questionnaireEditing={questionnaireEditing}
                      setQuestionnaireEditing={setQuestionnaireEditing}
                    />
                  ) : (
                    <CandidateProgress profileId={profileId} />
                  )}
                </Card>
              </Grid.col>
              {!application && isProfilePage && (
                <Grid.col>
                  <Card>
                    <ProfileProgress id={profileId} />
                  </Card>
                </Grid.col>
              )}
              <Grid.col>
                <CandidateStatus />
              </Grid.col>
              {application && (
                <>
                  <Grid.col>
                    <Card>
                      <CandidateRoleDetails
                        role={application?.missionRole}
                        mission={application?.missionRole?.mission}
                      />
                    </Card>
                  </Grid.col>
                </>
              )}
            </Grid>
          </Grid.col>
        </Grid>
        <Impersonating offset={0} />
      </NarrowContainer>
      <PublicFooter />
    </>
  );
};

const ROLE_APPLICATION_PAGE = [
  {
    to: LINKS.candidate_home,
    label: <FormattedMessage id="Nav.Application" />,
  },
];

const HOME_PAGE = [
  {
    to: LINKS.candidate_home,
    label: <FormattedMessage id="Nav.Candidate.Home" />,
  },
];

const PROFILE_PAGE = [
  {
    to: LINKS.candidate_profile,
    label: <FormattedMessage id="Nav.Candidate.Profile" />,
  },
];

export default Home;
