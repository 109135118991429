import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { Row } from "components/Containers";
import { margins, colors } from "style";
import { PrimaryLink } from "components/Links";
import { usePublicMissionRoleEligibility } from "hooks";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ApplicantAbandon
 *
 * @param {Object}    application
 */
const ApplicantAbandon = ({ application }) => {
  const role = application?.missionRole;
  const { handleWithdrawApplication } = usePublicMissionRoleEligibility({});

  const handleAbandon = useCallback(async () => {
    handleWithdrawApplication(application, role);
  }, [role]);

  return (
    <>
      <Row css={styles.container} gap="0.5rem">
        <div css={styles.icon_container}>
          <Icon type={ICON_TYPE.warning} size={ICON_SIZE.small} color="#000" />
        </div>
        <span css={styles.label}>
          <FormattedMessage id={"Missions.Applicants.ApplicantAbandon.Title1"} />
        </span>
        <span>
          <FormattedMessage
            id={"Missions.Applicants.ApplicantAbandon.Title2"}
            values={{
              link: (
                <PrimaryLink onClick={handleAbandon}>
                  <FormattedMessage id={"Missions.Applicants.ApplicantAbandon.Cta"} />
                </PrimaryLink>
              ),
            }}
          />
        </span>
      </Row>
    </>
  );
};

const styles = {
  container: css`
    margin-left: ${margins.normal};
  `,
  icon_container: css`
    background: ${colors.warning};
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  `,
  label: css`
    font-weight: 600;
  `,
};

ApplicantAbandon.propTypes = {
  application: PropTypes.object.isRequired,
};

export default ApplicantAbandon;
