import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { MUTATION_TYPE } from "hooks";
import { ADD_TEAM, UPDATE_TEAM } from "graphql/mutations";
import { GET_ORGANIZATIONS, GET_MISSIONS, GET_TEAMS } from "graphql/queries";
import { DECORATORS } from "components/Form";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { SQUAD_STATUS, TEAM_TYPES, TEAM_TYPES_OPTIONS } from "constants/index";

/**
 * SquadForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onComplete
 * @param {Object}    squad
 */
const SquadForm = ({ show, onClose, onComplete, squad, ...props }) => {
  const initialValues = squad
    ? { name: squad.name, missionId: squad.missionId, organizationId: squad.organization?.id, teamType: squad.teamType }
    : {};
  const updateIdProp = squad ? { updateId: squad.id } : {};

  const formProps = {
    initialValues,
    mutationType: squad ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_TEAM,
        update: UPDATE_TEAM,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_TEAMS],
    },
  };

  return (
    <ModalForm
      title={<FormattedMessage id={`Squads.${squad ? "Update" : "Create"}SquadForm.Title`} />}
      data={FORM_DATA(squad?.versionStatus === SQUAD_STATUS.active)}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      onCompleted={onComplete}
      closeOnComplete={false}
      {...updateIdProp}
      {...formProps}
      {...props}
    />
  );
};

const FORM_DATA = (disableOrganization) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Organization",
        type: TYPE.select,
        size: SIZE.xlarge,
        disabled: disableOrganization,
        properties: {
          name: "organizationId",
          queryName: GET_ORGANIZATIONS,
          queryKeyName: "organizations",
          refetchAfterMutate: false,
          labelKey: "name",
          valueKey: "id",
          isSearchable: true,
          querySearchFieldName: "search",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.organization,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Mission",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "missionId",
          placeholder: "Search by name...",
          isClearable: true,
          isSearchable: true,
          queryName: GET_MISSIONS,
          queryKeyName: "missions",
          queryFieldName: "missions",
          refetchAfterMutate: false,
          querySearchFieldName: "name",
          queryVariables: { filters: { name: null }, first: 10 },
          labelKey: "name",
          valueKey: "id",
        },
      },
      {
        label: "Squad Name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          placeholder: "Name of Squad",
          maxLength: 100,
          required: true,
        },
      },
      {
        label: "Type",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "teamType",
          options: TEAM_TYPES_OPTIONS,
          required: true,
          placeholder: "Select Type",
          defaultValue: TEAM_TYPES.team,
        },
      },
    ],
  },
];
SquadForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  squad: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

export default SquadForm;
