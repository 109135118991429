import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { PreferredRateTag } from "components/Profile";
import { FormattedMessage } from "react-intl";
import { TAG_SIZE } from "components/Tags";
import { RATE_FREQUENCIES } from "constants/index";

/**
 * ApplicationRate
 *
 * @param {Object} data
 */
const ApplicationRate = ({ data, ...props }) => {
  const { hourlyRateData } = data || {};
  const {
    applicantHourlyRateUsd,
    profileHourlyRateUsd,
    missionRoleTargetRateUsd,
    minimumApplicantHourlyRateUsd,
    minimumAssignedHourlyRateUsd,
    consideredHourlyRateUsd,
    currency,
  } = hourlyRateData || {};
  const frequency = RATE_FREQUENCIES.hourly;

  return (
    <DisplayBox
      numColumns={3}
      hideHeader
      data={[
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleRate" />,
          body: (
            <PreferredRateTag rate={{ amount: applicantHourlyRateUsd, currency, frequency }} size={TAG_SIZE.medium} />
          ),
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitlePreferredRate" />,
          body: (
            <PreferredRateTag rate={{ amount: profileHourlyRateUsd, currency, frequency }} size={TAG_SIZE.medium} />
          ),
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleSuggestedRate" />,
          body: <PreferredRateTag rate={data?.recommendedRate} size={TAG_SIZE.medium} />,
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleTargetRate" />,
          body: (
            <PreferredRateTag rate={{ amount: missionRoleTargetRateUsd, currency, frequency }} size={TAG_SIZE.medium} />
          ),
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleMinimumApplicantRate" />,
          body: (
            <PreferredRateTag
              rate={{ amount: minimumApplicantHourlyRateUsd, currency, frequency }}
              size={TAG_SIZE.medium}
            />
          ),
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleMinimumAssignedRate" />,
          body: (
            <PreferredRateTag
              rate={{ amount: minimumAssignedHourlyRateUsd, currency, frequency }}
              size={TAG_SIZE.medium}
            />
          ),
        },
        {
          title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleConsideredRate" />,
          body: (
            <PreferredRateTag rate={{ amount: consideredHourlyRateUsd, currency, frequency }} size={TAG_SIZE.medium} />
          ),
        },
      ]}
      {...props}
    />
  );
};

ApplicationRate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApplicationRate;
