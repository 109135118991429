import React from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useMissionRoleApplicant, useProfile } from "hooks";
import { Col } from "components/Containers";
import { APPLICATION_STEPS, LINKS } from "constants/index";
import Progress from "components/Progress";
import { ProfileProgress } from "components/Profile";
import { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { ApplicantProgress } from "components/Missions/Applicants";

/**
 * GlobalApplicantProgress
 *
 * @param {Object}  missionRoleApplicant
 * @param {String}  profileId
 * @param {String}  path
 * @param {Boolean} hasCodeChallengeRequirement
 */
const GlobalApplicantProgress = ({ missionRoleApplicant, profileId, path, ...props }) => {
  const applicationChecklist = missionRoleApplicant?.applicationChecklist;
  const navigate = useNavigate();
  const { getApplicationSteps } = useMissionRoleApplicant({});
  const { isProfileComplete } = useProfile({ profileId: missionRoleApplicant?.profile?.id });

  const STEPS = [
    {
      name: APPLICATION_STEPS.sign_up,
      label: "Missions.Applicants.ApplicantProgress.Step.signup",
      isComplete: true,
    },
    {
      name: APPLICATION_STEPS.role_application,
      selected: path === LINKS.candidate_home,
      label: "Missions.Applicants.ApplicantProgress.Step.role_application",
      isComplete: getApplicationSteps(applicationChecklist)?.every((step) => step.fulfilled),
      children: (
        <ApplicantProgress
          missionRoleApplicant={missionRoleApplicant}
          id={profileId}
          size={RENDER_SIZE.xsmall}
          hideHeader
          hideSubmitButton
          {...props}
        />
      ),
      onClick: () => navigate(LINKS.candidate_home),
    },
    {
      name: APPLICATION_STEPS.profile_completion,
      selected: path === LINKS.candidate_profile,
      label: "Missions.Applicants.ApplicantProgress.Step.profile_completion",
      isComplete: isProfileComplete,
      children: <ProfileProgress id={profileId} size={RENDER_SIZE.xsmall} hideHeader />,
      onClick: () => navigate(LINKS.candidate_profile),
    },
  ];

  return (
    <Col gap="1rem">
      <Progress title="Profile.CandidateProgress.Title" steps={STEPS} />
      <ApplicantProgress
        missionRoleApplicant={missionRoleApplicant}
        id={profileId}
        size={RENDER_SIZE.xsmall}
        hideProgress
        hideHeader
      />
    </Col>
  );
};

GlobalApplicantProgress.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  profileId: PropTypes.string.isRequired,
  path: PropTypes.string,
  hasCodeChallengeRequirement: PropTypes.bool,
};

export default GlobalApplicantProgress;
