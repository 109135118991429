import React from "react";
import { FormattedMessage } from "react-intl";
import { useModalConfirmation, useQueryData, useToast } from "hooks";
import { WITHDRAW_APPLICATION } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { GET_PUBLIC_MISSION_ROLE_ELIGIBILITY } from "graphql/queries";
import { Col } from "components/Containers";
import { LINKS } from "constants/index";

export default function usePublicMissionRoleEligibility({ missionRoleId }) {
  const { show } = useModalConfirmation();
  const { toast } = useToast();
  const [withdrawApplication] = useMutation(WITHDRAW_APPLICATION);

  const { loading, data, error } = useQueryData({
    queryName: GET_PUBLIC_MISSION_ROLE_ELIGIBILITY,
    skip: !missionRoleId,
    variables: { missionRoleId },
  });

  const handleWithdrawApplication = async (application, role, newRole) => {
    const response = await show(
      <FormattedMessage id={`Missions.Applicants.ApplicantAbandon.${newRole ? `Change` : ``}PromptTitle`} />,
      <Col gap="1rem">
        <span>
          <FormattedMessage
            id={`Missions.Applicants.ApplicantAbandon.${newRole ? `Change` : ``}PromptBody1`}
            values={{
              role: <b>{role?.name}</b>,
            }}
          />
        </span>
        <b>
          <FormattedMessage id={`Missions.Applicants.ApplicantAbandon.${newRole ? `Change` : ``}PromptBody2`} />
        </b>
      </Col>,
      {
        primaryButtonMessageId: "Missions.Applicants.ApplicantAbandon.Button.Cta",
      }
    );

    if (response) {
      withdrawApplication({
        variables: {
          input: {
            id: application?.id,
            newRoleId: newRole?.id,
          },
        },
        onCompleted: () => {
          window.location = LINKS.candidate_home;
          toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAbandon.Success" />);
        },
        onError: () => {
          toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAbandon.Error" />);
        },
      });
    }
  };

  return {
    loading,
    data: data?.publicMissionRoleEligibilityFetch,
    error,
    handleWithdrawApplication,
  };
}
