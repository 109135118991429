import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth, useProfile } from "hooks";
import PageTitle from "components/PageTitle";
import { NavList } from "components/Nav";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { getFullName } from "utils";

/**
 * ProfileNav
 *
 * @param {String}   id
 */
const ProfileNav = ({ id }) => {
  const { data, loading } = useProfile({ profileId: id });
  const { isAdmin, isPartner, isRecruiter } = useAuth();

  if (!isAdmin && !isRecruiter && !isPartner) return null;

  const adminNavItems = [
    {
      label: "Profile",
      to: LINKS.private_profile(id),
      isActive: true,
    },
    {
      label: "Interviews",
      to: LINKS.profile_video_interviews(id),
      isActive: true,
    },
    {
      label: "Job Preferences",
      to: LINKS.rate(id),
      isActive: true,
    },
    {
      label: "Code Challenges",
      to: LINKS.profile_code_challenges(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Applications",
      to: LINKS.profile_applications(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Assignments",
      to: LINKS.profile_assignments(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Notes",
      to: LINKS.notes(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Timeline",
      to: LINKS.timeline(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Settings",
      to: LINKS.settings(id),
      isActive: true,
    },
  ];

  const partnerNavItems = [
    {
      label: "Profile",
      to: LINKS.private_profile(id),
      isActive: true,
    },
    {
      label: "Interviews",
      to: LINKS.profile_video_interviews(id),
      isActive: true,
    },
    {
      label: "Job Preferences",
      to: LINKS.rate(id),
      isActive: true,
    },
  ];

  return (
    <PageTitle
      title={getFullName(data?.profile, true)}
      backLink={
        <PrimaryLink to={LINKS.network} routerEnabled>
          <FormattedMessage id="Nav.SideNav.LabelNetwork" />
        </PrimaryLink>
      }
      actionBar={<NavList pages={isAdmin || isRecruiter ? adminNavItems : partnerNavItems} routerEnabled end />}
      loading={loading}
      asHeader
    />
  );
};

ProfileNav.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProfileNav;
