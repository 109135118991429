import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatNumber, toUcFirst } from "utils";
import { PopoverCard } from "components/ActivityLog";
import ListTable from "components/ListTable";
import Score from "components/Score";
import { css } from "@emotion/react";

/**
 * MatchScoreDisplay
 *
 * @params {Object}    scores
 * @params {Object}    scoreData
 */
const MatchScoreDisplay = ({ scores, scoreData }) => {
  if (!scores) return <FormattedMessage id="Global.NA" />;

  return (
    <PopoverCard width="35rem" paddingBottom>
      <ListTable data={TABLE_DATA(scores, scoreData)} />
    </PopoverCard>
  );
};

const TABLE_DATA = (scores, scoreData) =>
  Object.entries(SCORE_KEYS_MAPPER).map(([key, value]) => ({
    label: (
      <>
        <div css={styles.weight}>{formatNumber(scores[value.weightKey], "x", 1)}</div>
        <Score score={(scores[key] * 100).toFixed(0)}>{(scores[key] * 100).toFixed(0)}%</Score>
        <span css={styles.popover_item_title}>{toUcFirst(value.id)}</span>
      </>
    ),
    value: (
      <>
        {value.getValue(scoreData[value.id]) !== null ? (
          <FormattedMessage id={value.msgKey} values={{ value: value.getValue(scoreData[value.id]) }} />
        ) : (
          "-"
        )}
        {value.valueAdjusted?.(scoreData[value.id]) ? (
          <FormattedMessage id="Missions.MissionCandidateList.MatchScore.AdjustmentIndicator" />
        ) : (
          <></>
        )}
        {value.getDetail ? <div css={styles.description}>{value.getDetail(scoreData[value.id])}</div> : <></>}
      </>
    ),
  }));

const SCORE_KEYS_MAPPER = {
  matchActivityScore: {
    id: "activity",
    weightKey: "weightActivity",
    msgKey: "Missions.MissionCandidateList.MatchScore.Activity",
    getValue: ({ consideredDaysSinceLastActivity }) => consideredDaysSinceLastActivity,
  },
  matchSkillsScore: {
    id: "skills",
    weightKey: "weightSkills",
    msgKey: "Missions.MissionCandidateList.MatchScore.Skills",
    getValue: ({ matchingSkillCount }) => matchingSkillCount,
    getDetail: ({ matchingSkills }) => matchingSkills?.join(", "),
  },
  matchExperienceScore: {
    id: "experience",
    weightKey: "weightExperience",
    msgKey: "Missions.MissionCandidateList.MatchScore.Experience",
    getValue: ({ consideredExperienceYears }) => consideredExperienceYears,
    valueAdjusted: ({ consideredExperienceYears, rawExperienceYears }) =>
      consideredExperienceYears != rawExperienceYears,
  },
  matchTimeZoneScore: {
    id: "timeZone",
    weightKey: "weightTimeZone",
    msgKey: "Missions.MissionCandidateList.MatchScore.Timezone",
    getValue: ({ consideredTimeZoneOffset }) => consideredTimeZoneOffset,
    valueAdjusted: ({ consideredTimeZoneOffset, rawTimeZoneOffset }) =>
      !rawTimeZoneOffset || rawTimeZoneOffset !== consideredTimeZoneOffset,
  },
  matchScorePrevious: {
    id: "Previous Score",
    msgKey: "Global.Value",
    getValue: () => "-",
  },
};

const styles = {
  weight: css`
    display: inline-block;
    font-weight: 400;
    width: 35px;
  `,
  popover_item_title: css`
    margin-left: 5px;
  `,
  description: css`
    margin-top: 5px;
  `,
  spacer: css`
    margin-right: 25px;
  `,
};

MatchScoreDisplay.propTypes = {
  scores: PropTypes.object.isRequired,
  scoreData: PropTypes.object.isRequired,
};

export default MatchScoreDisplay;
