import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { GET_MISSION_ROLES } from "graphql/queries";
import { ADD_MISSION_ROLE, CLOSE_MISSION_ROLE, REMOVE_MISSION_ROLES } from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";
import { useModalConfirmation, useQueryData, useToast } from "hooks";
import { ICON_TYPE } from "components/Icon";
import { ARCHETYPES, LINKS, MISSION_ROLE_STATES } from "constants/index";
import { Col } from "components/Containers";

/**
 * useMissionRoles
 *
 * @param {String}  missionId
 * @param {Object}  filters
 * @param {Number}  resultsPerPage
 * @param {Boolean} skipQuery
 */
export default function useMissionRoles({ missionId, filters = {}, resultsPerPage, skipQuery = false }) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const navigate = useNavigate();
  const variables = missionId ? { id: missionId } : {};
  const [showForm, setShowForm] = useState(false);
  const [showPublicViewForm, setShowPublicViewForm] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  const { loading, loadingMore, data, error, hasNextPage, handleNextPage } = useQueryData({
    queryName: GET_MISSION_ROLES,
    keyName: "missionRoles",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    skip: skipQuery,
    variables: {
      ...variables,
      ...{
        filters: filters,
      },
    },
  });

  const [removeMissionRoles, { loading: deleting }] = useMutation(REMOVE_MISSION_ROLES, {
    refetchQueries: [GET_MISSION_ROLES],
  });
  const [closeMissionRole, { loading: closing }] = useMutation(CLOSE_MISSION_ROLE, {
    refetchQueries: [GET_MISSION_ROLES],
  });
  const [duplicateMissionRole, { loading: duplicating }] = useMutation(ADD_MISSION_ROLE, {
    refetchQueries: [GET_MISSION_ROLES],
  });

  const buildFormData = (missionRole) => {
    const archetypes = Object.values(ARCHETYPES).map(({ id, name }) => ({ id, name }));

    return {
      name: missionRole?.name,
      description: missionRole?.description,
      descriptionJson: missionRole?.descriptionJson,
      archetype: archetypes.filter((item) => missionRole?.archetype?.some((id) => item.id === id)) || [],
      primarySkills: missionRole?.primarySkills?.map(({ name, id }) => ({ name, id })),
      secondarySkills: missionRole?.secondarySkills?.map(({ name, id }) => ({ name, id })),
      disciplines: missionRole?.disciplines?.map(({ name, id }) => ({ name, id })),
      startDate: missionRole?.startDate,
      engagementType: missionRole?.engagementType,
      engagementTime: missionRole?.engagementTime,
      targetRate: missionRole?.targetRate,
      targetRateCurrency: missionRole?.targetRateCurrency,
      timezone: missionRole?.timezone?.value,
      isVisible: missionRole?.state === MISSION_ROLE_STATES.published,
      state: missionRole?.state,
      assignedToId: missionRole?.assignedTo?.id,
      talentOwnerId: missionRole?.talentOwner?.id,
      remoteAnywhere: missionRole?.remoteAnywhere,
      workArrangement: missionRole?.workArrangement,
      location: missionRole?.location,
      challenges: missionRole?.challenges?.map(({ name, id }) => ({ name, id })),
      locations: missionRole?.locations?.map(({ name, id }) => ({ name, id })),
    };
  };

  const handleRemoveRoles = async (ids, isBatch, role) => {
    const response = await show(
      <FormattedMessage id={`Missions.Roles.RoleAdvancedOptions.Remove.Title${isBatch ? `Batch` : ``}`} />,
      <Col gap="1rem">
        <span>
          <FormattedMessage
            id={`Missions.Roles.RoleAdvancedOptions.Remove.Confirmation${isBatch ? `` : `1`}`}
            values={{
              name: <b>{role?.name}</b>,
            }}
          />
        </span>
        <b>
          <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Remove.Confirmation2" />
        </b>
      </Col>,
      {
        showCancel: true,
        showSave: false,
        showSaveAsDestructable: true,
        primaryButtonMessageId: `Missions.Roles.RoleAdvancedOptions.Remove.Cta${isBatch ? `Batch` : ``}`,
      }
    );

    if (response) {
      removeMissionRoles({
        variables: {
          input: { ids },
        },
        onCompleted: () =>
          toast.success(
            <FormattedMessage
              id={"useMissionRoles.RemoveSuccess"}
              values={{
                roleCount: ids.length,
              }}
            />
          ),
        onError: () =>
          toast.error(
            <FormattedMessage
              id={"useMissionRoles.RemoveError"}
              values={{
                roleCount: ids.length,
              }}
            />
          ),
      });
    }
  };

  /**
   * Triggers the close mission role query
   */
  const handleCloseRole = async (role) => {
    const response = await show(
      <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Close.Title" />,
      <Col gap="1rem">
        <span>
          <FormattedMessage
            id="Missions.Roles.RoleAdvancedOptions.Close.Confirmation1"
            values={{
              name: <b>{role?.name}</b>,
            }}
          />
        </span>
        <b>
          <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Close.Confirmation2" />
        </b>
      </Col>,
      {
        showCancel: true,
        showSave: false,
        showSaveAsDestructable: true,
        primaryButtonMessageId: "Missions.Roles.RoleAdvancedOptions.Close.Cta",
      }
    );

    if (response) {
      await closeMissionRole({
        variables: {
          input: { id: role?.id },
        },
        onCompleted: () => toast.success(<FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Close.Success" />),
        onError: () => toast.error(<FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Close.Error" />),
      });
    }
  };

  /**
   * Triggers the duplicate mission role query
   */
  const handleDuplicateRole = async (missionRole) => {
    const { mission } = missionRole;

    const response = await show(
      <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Duplicate.Title" />,
      <Col gap="1rem">
        <span>
          <FormattedMessage
            id="Missions.Roles.RoleAdvancedOptions.Duplicate.Confirmation1"
            values={{
              name: <b>{missionRole?.name}</b>,
            }}
          />
        </span>
        <b>
          <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Duplicate.Confirmation2" />
        </b>
      </Col>,
      {
        showCancel: true,
        showSave: true,
        primaryButtonMessageId: "Missions.Roles.RoleAdvancedOptions.Duplicate.Cta",
      }
    );

    if (response) {
      await duplicateMissionRole({
        variables: {
          input: {
            missionId: mission?.uuid,
            params: {
              ...buildFormData(missionRole),
              name: `[DUPLICATE] ${missionRole?.name}`,
              copiedRoleId: missionRole?.slug,
              isVisible: false,
            },
          },
        },
        onCompleted: async ({ addMissionRole }) => {
          navigate(LINKS.mission_role_details(mission?.uuid, addMissionRole?.missionRole?.slug));
          toast.success(<FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Duplicate.Success" />);
        },
        onError: () => toast.error(<FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Duplicate.Error" />),
      });
    }
  };

  const getAdvancedOptions = (data, selection) => {
    const editOption = {
      label: <FormattedMessage id={`Missions.Roles.RoleAdvancedOptions.Edit`} />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setSelectedRole(data);
        setShowForm(true);
      },
    };

    const removeOption = {
      label: (
        <FormattedMessage
          id={`Missions.Roles.RoleAdvancedOptions.${data ? `Remove` : `BatchRemove`}`}
          values={{
            roleCount: selection?.length || 0,
          }}
        />
      ),
      icon: ICON_TYPE.trash,
      onClick: () => handleRemoveRoles(data ? [data?.id] : selection, data ? false : true, data),
    };

    const copyOption = {
      label: <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Duplicate" />,
      icon: ICON_TYPE.copy,
      onClick: () => handleDuplicateRole(data),
    };

    const closeOption = {
      label: <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.Close" />,
      icon: ICON_TYPE.attention,
      onClick: () => handleCloseRole(data),
    };

    const configurePublicViewOption = {
      label: <FormattedMessage id="Missions.Roles.RoleAdvancedOptions.ConfigurePublicView" />,
      icon: ICON_TYPE.gear,
      onClick: () => {
        setSelectedRole(data);
        setShowPublicViewForm(true);
      },
    };

    const options = [removeOption];

    if (data) {
      options.unshift(editOption);
      options.push(copyOption);
      options.push(configurePublicViewOption);

      if (data?.state !== MISSION_ROLE_STATES.closed) {
        options.push(closeOption);
      }
    }

    return options;
  };

  return {
    loading,
    loadingMore,
    deleting,
    closing,
    duplicating,
    handleRemoveRoles,
    getAdvancedOptions,
    showForm,
    setShowForm,
    showPublicViewForm,
    setShowPublicViewForm,
    buildFormData,
    selectedRole,
    data: data?.missionRoles?.nodes,
    error,
    hasNextPage,
    handleNextPage,
  };
}
