import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { colors, fontSize } from "style";
import Score from "components/Score";
import { convertToPercent, formatNumber, snakeToCapitalized } from "utils";
import { Row } from "components/Containers";

/**
 * ApplicationMatchingTags
 *
 * @param {Object} data
 * @param {Boolean} loading
 */
const ApplicationMatchingTags = ({ data, loading, ...props }) => {
  const { missionRole, profile } = data || {};
  const disciplines = profile?.disciplines?.map((d) => d.id);
  const skills = profile?.skills?.map((d) => d.id);

  return (
    <DisplayBox
      numColumns={3}
      loading={loading}
      hideHeader
      data={[
        {
          title: <FormattedMessage id="Missions.Applicants.ApplicationMatchingTags.LabelDisciplines" />,
          body: (
            <MatchingTags
              tags={missionRole?.disciplines.map((item) => ({
                ...item,
                color: disciplines.includes(item.id) ? colors.green : colors.grayAnatomyBase,
              }))}
            />
          ),
        },
        {
          title: <FormattedMessage id="Missions.Applicants.ApplicationMatchingTags.LabelSkills" />,
          body: (
            <MatchingTags
              tags={missionRole?.primarySkills.map((item) => ({
                ...item,
                color: skills.includes(item.id) ? colors.green : colors.grayAnatomyBase,
              }))}
            />
          ),
        },
        {
          title: <FormattedMessage id="Missions.Applicants.ApplicationMatchingTags.LabelSkillsNiceToHave" />,
          body: (
            <MatchingTags
              tags={missionRole?.secondarySkills.map((item) => ({
                ...item,
                color: skills.includes(item.id) ? colors.green : colors.grayAnatomyBase,
              }))}
            />
          ),
        },
        ...(data?.subScores
          ? Object.entries(data?.subScores)?.map(([key, value]) => ({
              title: snakeToCapitalized(key),
              body: (
                <Row>
                  <Score score={Number(value.score) * 100}>{convertToPercent(value.score)}</Score>
                  <span css={styles.weight}>Weight: {formatNumber(value.weight || 0, "x", 1)}</span>
                </Row>
              ),
            }))
          : []),
      ]}
      {...props}
    />
  );
};

/**
 * MatchingTags
 *
 * @param {Array} tags
 */
const MatchingTags = ({ tags }) => {
  return (
    <>
      {tags.length === 0 && <span>—</span>}
      <Tags data={tags} isRemoveable={false} />
    </>
  );
};

const styles = {
  weight: css`
    font-size: ${fontSize.xsmall};
  `,
};

MatchingTags.propTypes = {
  tags: PropTypes.array,
};

ApplicationMatchingTags.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default ApplicationMatchingTags;
