/** @jsx jsx */
import styled from "@emotion/styled";
import { colors } from "../../style";

/**
 * TableRow
 */
const TableRow = styled.tr`
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &:nth-child(even) {
    background: ${colors.purpleRainLight8};
  }

  &.actionable {
    cursor: pointer;

    &:hover {
      td {
        background: ${colors.purpleRainLight5};

        .transparent:not(:focus) {
          background: #fff;
        }
      }
    }
  }

  ${(props) =>
    props.expanded &&
    `
    background: ${colors.purpleRainLight5};
  `}

  ${(props) =>
    props.expandable &&
    `
    background: ${colors.purpleRainLight3};
  `}

  &:last-of-type {
    td {
      border-bottom-width: 0;
    }
  }
`;

export default TableRow;
