/**
 * Returns the embed YT url for iframe usage
 *
 * @param {String} url
 *
 * @returns {string}
 */
export default function convertYouTubeVideoUrl(url) {
  if (url) {
    const convertedUrl = new URL(url);
    const searchParams = new URLSearchParams(convertedUrl.search);
    const videoId = searchParams.get("v");

    // Look for v value in url
    if (videoId) {
      return `https://www.youtube.com/embed/${searchParams.get("v")}`;

      // Otherwise assume id is at the end of the url
    } else {
      const videoUrlId = url.split("/")?.at(-1);

      return `https://www.youtube.com/embed/${videoUrlId}`;
    }
  }

  return;
}
